import { appColors } from '@config/theme';

export const MenuButton = ({
  Icon,
  text,
  selected,
  onClick,
}: {
  Icon: ({ color }: { color?: string | undefined }) => JSX.Element;
  text: string;
  selected?: boolean;
  onClick?: () => void;
}) => (
  <div
    onClick={onClick}
    style={{ backgroundColor: selected ? appColors.blue : undefined }}
    className='group w-full h-[65px] bg-white p-[20px] gap-[5px] flex items-center hover:bg-blue active:bg-blue-600 cursor-pointer'
  >
    <div className='group-hover:hidden'>
      {<Icon color={selected ? appColors.white : appColors.blue} />}
    </div>
    <div className='hidden group-hover:block'>{<Icon color='white' />}</div>
    <text
      style={{ color: selected ? appColors.white : undefined }}
      className='font-bold text-blue group-hover:text-white font-helvetica'
    >
      {text}
    </text>
  </div>
);
