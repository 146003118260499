import Link from 'antd/es/typography/Link';

export type ImgGeneratorLinkProps = {
  white?: boolean;
  blue?: boolean;
  children?: React.ReactNode;
  backIcon?: React.ReactNode;
  forwardIcon?: React.ReactNode;
  styles?: any;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  props?: any;
};

export const ImgGeneratorLink = ({
  children,
  backIcon,
  forwardIcon,
  styles,
  disabled,
  className,
  onClick,
  ...props
}: ImgGeneratorLinkProps) => (
  // const bgColor = disabled ? (!white ? appColors['bg-disabled'] : appColors['bg-white-disabled']) : blue ? appColors.blue : '';

  // const disabledTextColor = disabled ? (!white ? appColors['blue-100'] : appColors['blue-100']) : '';

  <Link
    {...props}
    onClick={onClick}
    disabled={disabled}
    style={{ ...styles }}
    className={`flex gap-6px items-center break-normal !text-black hover:!text-blue font-medium ${className}`}
  >
    {backIcon}
    {children}
    {forwardIcon}
  </Link>
);
