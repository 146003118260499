import { observer } from 'mobx-react-lite';

import { useLogic, useStore } from '@hooks/storeHook';

import { Typography } from 'antd';

import { useState } from 'react';
import React from 'react';
import { SearchField } from '@components/ui/SearchField/SearchField';
import { ImgGeneratorButton } from '@components/ui/Button/Button';

import { LoadState } from '@@types/loadState';

import { LoadIcon } from '@components/icons/loadIcon';
import { appColors } from '@config/theme';
import { TagList } from '@components/ui/TagList/TagList';
import { PlusSmallIcon } from '@components/icons/PlusSmallIcon';
import { CloseSmallIcon } from '@components/icons/CloseSmallIcon';
import { ProLayout } from '@components/ui/ProLayout/ProLayout';

export const EnterLinksScreen = observer(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { Text } = Typography;

  const {
    editorStore: {
      projectData,
      featuresLoading,
      onDeleteFeature,
      onSelectFeature,
      selectedFeatures,
      recommendedFeatures,
    },
  } = useStore();

  const logic = useLogic();

  const [fields, setFields] = useState(['', '']);

  const onChangeFields = (index: number) => (value: string) => {
    const newArr = fields.map((item: any, i: number) =>
      index === i ? value : item,
    );
    setFields(newArr);
  };

  const onAnalyze = async () => {
    const form = {
      competitors: fields.filter((item: string | any[]) => item.length !== 0),
      id: projectData?.id ?? -1,
    };
    await logic.generateFeatures(form);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // async function onFinish() {
  //   const selected: any = {};
  //   selectedTagsList.forEach(
  //     (item) =>
  //       (selected[`${item}`] =
  //         projectData?.recommended_features?.[`${item}`] ?? ''),
  //   );
  //   const form = {
  //     selected_features: selected,
  //     id: projectData?.id ?? -1,
  //   };

  //   const res = await logic.updatingProjectQuiz(form);
  //   if (!res) {
  //     return toastController('Произошла ошибка');
  //   }
  // }

  if (featuresLoading === LoadState.Loading) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <LoadIcon color={appColors.blue} />{' '}
      </div>
    );
  }

  return (
    <div className='w-full h-full  flex flex-col justify-start items-start gap-[20px] '>
      <div className='flex gap-[10px] flex-col w-full relative'>
        {fields.map((_, index) => (
          <SearchField
            onChange={onChangeFields(index)}
            value={fields[index]}
            placeholder={'Вставьте ссылку на товар Wildberries'}
            maxLength={60}
            disabled={index >= 2}
          />
        ))}
      </div>
      <div className='flex gap-[20px] flex-col w-full'>
        <ProLayout>
          <ImgGeneratorButton className='!justify-start !pl-[20px]' white>
            До 10 ссылок
          </ImgGeneratorButton>
        </ProLayout>
        <ImgGeneratorButton onClick={onAnalyze}>
          Сгенерировать преимущества
        </ImgGeneratorButton>
      </div>
      {Object.keys(projectData?.recommended_features).length > 0 && (
        <div className='flex gap-[22px] flex-col w-full  max-w-[600px] relative'>
          <div className='border-solid border-grey-secondary border-0 border-t-[1px]  mx-[-12px] max-h-[289px] overflow-y-auto'>
            <div className=' py-[20px]  px-[20px] '>
              <Text className='text-lg font-bold text-black uppercase'>
                Основные преимущества:
              </Text>
              <TagList
                tags={recommendedFeatures}
                containerClassName={'flex gap-y-[8px] flex-wrap mt-[16px] '}
                onClick={onSelectFeature}
                icon={<PlusSmallIcon />}
                tagClassName={`bg-blue-100 px-[10px] py-[5px] max-h-[31px] rounded-[5px] !w-full `}
                linkClassName={`gap-[8px] !text-blue !text-[14px] !font-normal`}
              />
            </div>
          </div>
          <div className='border-solid border-grey-secondary border-0 border-t-[1px]  mx-[-12px] max-h-[181px] overflow-y-auto'>
            <div className=' py-[20px]  px-[20px] '>
              <Text className='text-lg font-bold text-black uppercase'>
                Выбранные преимущества:
              </Text>
              <TagList
                onClick={onDeleteFeature}
                containerClassName={'flex gap-y-[8px] flex-wrap mt-[20px]'}
                tags={selectedFeatures}
                icon={<CloseSmallIcon />}
                tagClassName={`bg-blue px-[10px] py-[5px] max-h-[31px] rounded-[5px] !w-full `}
                linkClassName={`gap-[8px] !text-white !text-[14px] !font-normal hover:!text-white`}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
