import { Input, Typography } from 'antd';
import { useLayoutEffect, useState } from 'react';

type Iprops = {
  value?: string | readonly string[] | undefined;
  style?: React.CSSProperties | undefined;
  defaultValue?: string | number | readonly string[] | undefined;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined;
  placeholder?: string | undefined;
  generating?: boolean;
  maxLength?: number | undefined;
  fontSize: any;
  scaleRatio?: number;
  hover?: boolean;
};

export const BannerTextArea = (props: Iprops) => {
  const [fontScale, setFontScale] = useState(parseInt(props.fontSize));
  const [cursor, setCursor] = useState('pointer');

  useLayoutEffect(() => {
    const length = props.value?.length;
    const fontSize = parseInt(props.fontSize);
    if (length && fontSize && props.maxLength) {
      if (length > props.maxLength)
        setFontScale(
          fontSize -
            ((length - props.maxLength) * fontSize * (props.scaleRatio ?? 1)) /
              100,
        );
      else setFontScale(parseInt(props.fontSize));

      // if (textAreaRef.current)
      //   textAreaRef.current.style.height =
      //     textAreaRef.current.scrollHeight + 'px';
    }
  }, [props]);

  return (
    <>
      {props.generating ? (
        <Typography.Text
          style={{
            ...props.style,
            paddingLeft: 15,
            paddingRight: 15,
            fontSize: `${fontScale}px`,
            boxDecorationBreak: 'clone',
            WebkitBoxDecorationBreak: 'clone',
          }}
        >
          {props.value ?? props.defaultValue}
        </Typography.Text>
      ) : (
        <Input.TextArea
          style={{
            ...props.style,
            fontSize: `${fontScale}px`,
            cursor,
            caretColor: '#FFBF00',
          }}
          placeholder='Описание'
          autoSize
          className={
            `!overflow-hidden !min-h-[0px] ` + props.hover ??
            'hover:outline-[6px] hover:outline hover:outline-blue-600 pointer-events-none'
          }
          onChange={props.onChange}
          defaultValue={props.defaultValue}
          value={props.value}
          bordered={false}
          onFocus={setCursor.bind(this, 'text')}
          onBlur={setCursor.bind(this, 'pointer')}
        />
      )}
    </>
  );
};
