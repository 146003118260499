import { templateTypeKeys } from '@components/editor/typeStyles/basicStyles';

export enum BannerItemPosition {
  left = 'left',
  right = 'right',
  up = 'up',
  down = 'down',
  center = 'center',
}

export type BannerConfigType = {
  [key in string]: {
    [key in BannerItemPosition]?: templateTypeKeys;
  };
};

export const BannerConfig: BannerConfigType = {
  '1': {
    right: templateTypeKeys.first,
    left: templateTypeKeys.second,
    down: templateTypeKeys.third,
    up: templateTypeKeys.fourth,
    center: templateTypeKeys.fifth,
  },
  '2': {
    right: templateTypeKeys.fourth,
    left: templateTypeKeys.second,
    down: templateTypeKeys.first,
    up: templateTypeKeys.third,
    center: templateTypeKeys.fifth,
  },
  '3': {
    right: templateTypeKeys.third,
    left: templateTypeKeys.second,
    down: templateTypeKeys.fourth,
    up: templateTypeKeys.first,
    center: templateTypeKeys.fifth,
  },
  '4': {
    down: templateTypeKeys.second,
    center: templateTypeKeys.first,
  },
  '5': {
    right: templateTypeKeys.second,
    left: templateTypeKeys.third,
    down: templateTypeKeys.fourth,
    up: templateTypeKeys.first,
    center: templateTypeKeys.fifth,
  },
  '6': {
    right: templateTypeKeys.second,
    left: templateTypeKeys.first,
    down: templateTypeKeys.fourth,
    up: templateTypeKeys.fifth,
    center: templateTypeKeys.third,
  },
  '7': {
    right: templateTypeKeys.first,
    left: templateTypeKeys.second,
  },
  '8': {
    right: templateTypeKeys.first,

    down: templateTypeKeys.fifth,
    up: templateTypeKeys.third,
    center: templateTypeKeys.fourth,
  },
  '9': {
    down: templateTypeKeys.first,

    center: templateTypeKeys.second,
  },
};
