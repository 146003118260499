import './App.css';

import { withConfigs } from '@config/withConfigs';
import { ScrollToTop } from '@common/scrollToTop';
import { Route, Routes } from 'react-router-dom';
import { AppRoutes, AppRoutesType } from '@config/navigation';
import './common/commonStyles.css';

export const App = withConfigs(() => {
  const renderRoutes = (routes?: AppRoutesType) => {
    if (!routes) {
      return null;
    }
    return routes.map((route) => (
      <Route key={route.name} element={route.element} path={route.path}>
        {renderRoutes(route.childs)}
      </Route>
    ));
  };

  return (
    <ScrollToTop>
      <Routes>{renderRoutes(AppRoutes)}</Routes>
    </ScrollToTop>
  );
});
