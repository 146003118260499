export const PlusSmallIcon = () => (
  <svg
    width='12'
    height='13'
    viewBox='0 0 12 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M6 11.4998L6 1.50001'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M11 6.49982L1.0002 6.49982'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
