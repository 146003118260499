import { appColors } from '@config/theme';

export const EditorMenuColorIcon = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M31.7926 6.81559C30.7191 5.74201 29.0127 5.63974 27.8186 6.5774L17.794 14.4491C16.7923 15.2356 16.827 16.7637 17.8634 17.5039C19.8141 18.8973 21.5208 20.604 22.9142 22.5548C23.6544 23.5911 25.1825 23.6258 25.969 22.6242L33.8407 12.5996C34.7784 11.4054 34.6761 9.69905 33.6025 8.62547L31.7926 6.81559ZM29.0537 8.15041C29.4518 7.83785 30.0206 7.87194 30.3784 8.2298L32.1883 10.0397C32.5462 10.3975 32.5803 10.9663 32.2677 11.3644L24.4709 21.2937C22.986 19.2372 21.1809 17.4321 19.1244 15.9472L29.0537 8.15041ZM17.7971 27.9515C16.9357 28.8129 14.8676 30.1149 12.7009 30.9207C11.9091 31.2151 11.1778 31.4148 10.5525 31.5116C11.5682 29.1963 11.7996 27.2795 11.8989 25.7214C11.9179 25.4232 11.9318 25.1633 11.9442 24.9316L11.9442 24.9316C11.9767 24.3239 11.9989 23.9102 12.0755 23.5085C12.1578 23.0774 12.2765 22.8518 12.487 22.6413C13.9533 21.1749 16.3308 21.1749 17.7971 22.6413C19.2635 24.1076 19.2635 26.4851 17.7971 27.9515ZM19.2114 29.3657C17.4226 31.1544 12.5396 33.7291 9.48796 33.5723C9.10043 33.5524 8.74243 33.4884 8.42405 33.3732C8.09959 33.2558 7.81628 33.0851 7.58478 32.8536C9.70185 29.4338 9.83607 26.9019 9.94 24.9412C10.0216 23.4023 10.0845 22.2153 11.0728 21.2271C13.3202 18.9797 16.9639 18.9797 19.2114 21.2271C21.4588 23.4745 21.4588 27.1183 19.2114 29.3657Z'
      fill={color}
    />
  </svg>
);
