import { appColors } from '@config/theme';

export const EditorMenuButton = ({
  Icon,
  text,
  selected,
  onClick,
}: {
  Icon: ({ color }: { color?: string | undefined }) => JSX.Element;
  text: string;
  selected?: boolean;
  onClick?: () => void;
}) => (
  <div
    onClick={onClick}
    style={{ backgroundColor: selected ? appColors['blue-600'] : undefined }}
    className='group w-full sm:w-[133px] h-full flex flex-col justify-center items-center rounded  hover:bg-blue-600 cursor-pointer'
  >
    <div className='group-hover:hidden'>
      {<Icon color={selected ? appColors.white : appColors['blue-600']} />}
    </div>
    <div className='hidden group-hover:flex'>{<Icon color='white' />}</div>
    <text
      style={{ color: selected ? appColors.white : undefined }}
      className='font-bold text-blue group-hover:text-white text-center font-helvetica'
    >
      {text}
    </text>
  </div>
);
