import { Tag } from 'antd';
import { ImgGeneratorLink } from '../ImgGeneratorLink/ImgGeneratorLink';
import './styles.css';
export type TagListProps = {
  tags: string[];
  icon?: React.ReactNode;
  tagClassName?: any;
  containerClassName?: any;
  linkClassName?: any;
  onClick?: (item: number) => void;
};

export const TagList = ({
  onClick,
  tags,
  icon,
  tagClassName,
  containerClassName,
  linkClassName,
}: TagListProps) => {
  function onDeleteTag(index: number) {
    if (onClick) {
      onClick(index);
    }
  }
  return (
    <div className={`${containerClassName}`}>
      {tags.length > 0 &&
        tags.map((item, index: React.Key) => (
          <Tag
            className={`min-h-[24px] border-0 ${tagClassName}`}
            icon={
              <ImgGeneratorLink
                onClick={() => onDeleteTag(Number(index))}
                className={`justify-between ${linkClassName}`}
                forwardIcon={icon}
              >
                {item}
              </ImgGeneratorLink>
            }
            key={index}
          />
        ))}
    </div>
  );
};
