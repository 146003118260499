import { LoadState } from '@@types/loadState';
import { ApiProjectQuiz } from '@@types/quiz';
import { VisibleError } from '@common/visibleError';

import { action, observable, reaction } from 'mobx';

import { BannerItem } from './banner/BannerItem';
import { BannerItemPosition } from '@config/bannersConfig';

export class QuizStore {
  @observable
  step = 1;

  @observable
  template: number | null = null;

  @observable
  position: BannerItemPosition | null = null;

  @observable
  commonLoading: LoadState = LoadState.Initial;

  @observable
  imageLoading: LoadState = LoadState.Initial;

  @observable
  projectData: ApiProjectQuiz | null = null;

  @observable
  gridDisplay = false;

  @observable
  banner: BannerItem | null = null;

  constructor(projectData?: ApiProjectQuiz, template?: number | null) {
    if (projectData) {
      this.projectData = projectData;
      this.step = 2;
      this.template = template ?? null;
      // this.step = projectData.step >= 4 ? 4 : projectData.step;
    }
    this.checkStep();
    reaction(
      () => this.step,
      action(() => {
        this.checkStep();
      }),
    );
  }
  @action
  checkStep = () => {
    if (this.step === 5) {
      if (this.projectData) {
        this.banner = new BannerItem(this.projectData);
      }
    }
  };

  @action
  setTemplate = (id: number) => {
    this.template = id;
  };

  @action
  setPosition = (position: BannerItemPosition) => {
    this.position = position;
  };

  @action
  setStep = (stepNumber: number, withoutCheck?: boolean) => {
    if (stepNumber < 1 || stepNumber > 5) {
      throw new VisibleError('Step does not exist');
    }
    if (withoutCheck) {
      this.step = stepNumber;
      return;
    } else {
      // Тут будут какие - то проверки в будущем, чтобы перейти на след шаг в зависимости от текущщего
      this.step = stepNumber;
    }
  };
}
