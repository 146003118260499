import { ApiDinymicObject, ApiUser, userFormType } from '@@types/auth';
import { ApiUserPlan } from '@@types/billing';
import {
  ApiAllProjects,
  ApiAllProjectsSortType,
  ApiBulletPoints,
  ApiCountryQuiz,
  ApiFormData,
  ApiGetInfoById,
  ApiMoreInfoProject,
  ApiObjectInfoMore,
  ApiPlatformQuiz,
  ApiProjectQuiz,
  ApiTargetQuiz,
} from '@@types/quiz';
import { toastController } from '@common/toastController';
import { VisibleError } from '@common/visibleError';

import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

interface ApiResponse<T = any> {
  data: T;
  message: string;
  status: number;
  success: boolean;
}

export class ApiLogic {
  private readonly axios: AxiosInstance;

  constructor() {
    this.axios = Axios.create({
      baseURL: 'https://api.card.mp360.ru/api/',
      headers: { Accept: 'application/json' },
      timeout: 120 * 1000,
    });

    this.axios.interceptors.request.use(
      this.interceptRequest,
      this.interceptRequestError,
    );

    this.axios.interceptors.response.use(
      this.interceptResponse,
      this.interceptResponseError,
    );
  }

  private interceptRequest = async (request: AxiosRequestConfig) => {
    console.log(
      `[REQUEST] ${request.method} => ${request.baseURL}${request.url}`,
      {
        data: JSON.stringify(request.data),
      },
    );

    return request;
  };

  private interceptRequestError = (error?: Partial<AxiosError>) => {
    if (Axios.isCancel(error)) {
      console.log(
        `[REQUEST CANCEL] ${error?.config?.method} ${error?.config?.url}`,
        { data: error?.config?.data },
      );
      throw new VisibleError('Запрос отменён', error);
    } else {
      console.log(
        `[REQUEST ERROR] ${error?.config?.method} ${error?.config?.url}`,

        JSON.stringify(error),
      );

      throw error;
    }
  };

  private interceptResponse = (response: AxiosResponse) => {
    console.log(
      `[RESPONSE] ${response.config.method} ${response.config.url} => ${response.status}`,
      //, {data: JSON.stringify(response.data, null, 2)}
    );

    return response.data;
  };

  private interceptResponseError = async (error?: Partial<AxiosError>) => {
    if (Axios.isCancel(error)) {
      console.log(
        `[RESPONSE CANCEL] ${error?.config?.method} ${error?.config?.url}`,
      );
      throw new VisibleError('Запрос отменён', error);
    }

    if (error?.response) {
      const { status, data } = error.response;

      console.log(
        `[RESPONSE ERROR] ${error?.config?.method} ${error?.config?.url} => ${status}`,
        {
          responseData: JSON.stringify(data, null, 2),
        },
      );
    } else {
      console.log(
        `[RESPONSE ERROR] ${error?.config?.method} ${error?.config?.url} => no response`,
        JSON.stringify(error),
      );
    }

    throw error;
  };

  private authConfig = (authToken: string) =>
    ({ headers: { ['Authorization']: `Bearer ${authToken}` } }) as const;

  loadUser = (token: string): Promise<ApiUser> =>
    this.axios.get('user/profile', this.authConfig(token)).then(getData);

  loadUserPlan = (token: string): Promise<ApiUserPlan> =>
    this.axios.get('billing/users/plan', this.authConfig(token)).then(getData);

  postContextKey = (contextKey: string): Promise<any> =>
    this.axios.post('mysklad/user', { contextKey });

  login = async (email: string, password: string): Promise<string> => {
    let response: ApiResponse<string>;

    try {
      response = await this.axios.post('auth/login', { email, password });
    } catch (e: any) {
      if (e.response?.status === 422) {
        throw new VisibleError('Email или пароль не верны.', e);
      }

      throw e;
    }

    if (!response.data) {
      throw new VisibleError('Не удалось войти');
    }

    return response.data;
  };

  registrationUser = async (form: unknown): Promise<unknown> => {
    let response: ApiResponse<any>;

    try {
      response = await this.axios.post('auth/register', form);
    } catch (e: any) {
      if (e.response?.status === 422) {
        /* empty */
      }

      throw e;
    }
    if (!response?.data) {
      throw new VisibleError('Не удалось создать проект');
    }

    return response.data;
  };
  sendEmail = async (form: { email: string }): Promise<any> => {
    let response: ApiResponse<any>;

    try {
      response = await this.axios.post('auth/sendCode', form);
    } catch (e: any) {
      if (e.response?.status === 422) {
        toastController(e.response.data.message ?? 'Код уже отправлен');
      }

      throw e;
    }

    return response.data;
  };
  sendCodeEmail = async (form: { email: string; code: string }): Promise<any> =>
    await this.axios.post('auth', form).then(getData);

  sendSubscribeForm = async (form: {
    email: string;
    phone: string;
    name: string;
  }): Promise<any> =>
    await this.axios.post('auth/announce', form).then(getData);

  getProjectById = async (id: number, token: string): Promise<ApiProjectQuiz> =>
    await this.axios.get(`project/${id}`, this.authConfig(token)).then(getData);

  createProjectQuiz = async (
    form: ApiFormData,
    token: string,
  ): Promise<ApiProjectQuiz> =>
    await this.axios
      .post('project/set', form, this.authConfig(token))
      .then(getData);

  removingImgQuiz = async (
    id: number,
    token: string,
  ): Promise<ApiProjectQuiz> =>
    this.axios
      .post('project/removeImage', { image_id: id }, this.authConfig(token))
      .then(getData);

  updateMoreInfoProjectQuiz = async (
    form: ApiObjectInfoMore,
    token: string,
  ): Promise<ApiMoreInfoProject> =>
    this.axios
      .post('project/set/info', form, this.authConfig(token))
      .then(getData);

  setProjectStep = async (
    id: number,
    step: number,
    token: string,
  ): Promise<ApiProjectQuiz[]> =>
    this.axios.post(`project/${id}/step/set`, { step }, this.authConfig(token));

  getRecentProjects = async (token: string): Promise<ApiProjectQuiz[]> =>
    this.axios.get(`project/recent`, this.authConfig(token)).then(getData);

  getAllProjects = async (
    type: ApiAllProjectsSortType,
    page: number,
    count: number,
    token: string,
  ): Promise<ApiAllProjects> =>
    this.axios
      .get(
        `project?relevant=${Number(
          type === ApiAllProjectsSortType.revelant,
        )}&popularity=${Number(
          type === ApiAllProjectsSortType.popularity,
        )}&recent=${Number(
          type === ApiAllProjectsSortType.recent,
        )}&saved=${Number(
          type === ApiAllProjectsSortType.saved,
        )}&page=${page}&per_page=${count}`,
        this.authConfig(token),
      )
      .then(getData);

  getProjectInfoById = async (
    id: number,
    token: string,
  ): Promise<ApiGetInfoById> =>
    this.axios.get(`project/${id}/info`, this.authConfig(token)).then(getData);

  loadPlatformQuiz = async (token: string): Promise<ApiPlatformQuiz[]> =>
    this.axios.get('ordering/platforms', this.authConfig(token)).then(getData);

  getTargetsQuiz = async (token: string): Promise<ApiTargetQuiz> =>
    this.axios.get('ordering/targets', this.authConfig(token)).then(getData);

  getCountries = async (token: string): Promise<ApiCountryQuiz> =>
    this.axios.get('ordering/countries', this.authConfig(token)).then(getData);

  postBulletPoint = async (
    bulletPoint: string,
    projectId: number,
    token: string,
  ): Promise<ApiBulletPoints> =>
    this.axios
      .post(
        'project/bullet/add',
        { text: bulletPoint, project_id: projectId },
        this.authConfig(token),
      )
      .then(getData);

  getTypesReentro = async (token: string): Promise<ApiDinymicObject> =>
    this.axios
      .get('settings/verify/types', this.authConfig(token))
      .then(getData);

  getTypeSendCode = async (token: string): Promise<ApiDinymicObject> =>
    this.axios
      .get('settings/verify/sendTypes', this.authConfig(token))
      .then(getData);

  getLanguageApp = async (token: string): Promise<ApiDinymicObject> =>
    this.axios.get('settings/languages', this.authConfig(token)).then(getData);

  postUpdatingBullet = async (
    id: number,
    text: string,
    token: string,
  ): Promise<ApiProjectQuiz> =>
    this.axios
      .put(`project/bullet/update/${id}`, { id, text }, this.authConfig(token))
      .then(getData);

  postDeleteBullet = async (
    id: number,
    token: string,
  ): Promise<ApiProjectQuiz> =>
    this.axios
      .delete(`project/bullet/delete/${id}`, this.authConfig(token))
      .then(getData);

  postUpdatingAccount = async (
    form: userFormType,
    token: string,
  ): Promise<ApiDinymicObject> =>
    this.axios
      .post('user/profile/update', form, this.authConfig(token))
      .then(getData);

  setMainImage = async (
    image_id: number,
    value: number,
    token: string,
  ): Promise<ApiProjectQuiz> =>
    this.axios
      .post(
        'project/selectImageIsMain',
        { image_id, is_main: value },
        this.authConfig(token),
      )
      .then(getData);

  addFavoriteTemplate = async (
    id: number,
    token: string,
  ): Promise<{ id: number }> =>
    this.axios
      .post('template/favourite/add', { id }, this.authConfig(token))
      .then(getData);

  removeFavoriteTemplate = async (
    id: number,
    token: string,
  ): Promise<{ id: number }> =>
    this.axios
      .delete(`template/favourite/remove/${id}`, this.authConfig(token))
      .then(getData);
}

const getData = <T>(response: AxiosResponse<T>) => response.data;
