import { NavigationTemplateIcon } from '@components/icons/new-editor/navigationTemplate';
import { MenuButton } from './MenuButton';
import { NavigationPhotoIcon } from '@components/icons/new-editor/navigationPhoto';
import { NavigationFeaturesIcon } from '@components/icons/new-editor/navigationFeatures';

import { ReactNode, useState } from 'react';
import { MenuType } from '@hooks/templateMenuHooks';
import { FooterButton } from '../Footer/FooterButton';
import { NavigationTemplateIconMobile } from '@components/icons/new-editor/navigationTemplateMobile';
import { NavigationPhotoIconMobile } from '@components/icons/new-editor/navigationPhotoMobile';
import { NavigationFeaturesIconMobile } from '@components/icons/new-editor/navigationFeaturesMobile';
import { DesignIconMobile } from '@components/icons/new-editor/DesignIconMobile';

import { EditorMenuTextIconMobile } from '@components/icons/new-editor/editorMenuTextMobile';
import { EditorBackIconMobile } from '@components/icons/new-editor/EditorBackIconMobile';
import { EditorMenuFigureIconMobile } from '@components/icons/new-editor/editorMenuFigureMobile';
import { EditorMenuObjectIconMobile } from '@components/icons/new-editor/editorMenuObjectMobile';
import { EditorMenuColorIconMobile } from '@components/icons/new-editor/editorMenuColorMobile';
import { ProLayout } from '../ProLayout/ProLayout';
import { DownloadIconMobile } from '@components/icons/new-editor/downloadIconMobile';
import { useStore } from '@hooks/storeHook';

export const MenuButtonsComponent = ({
  selectedMenu,
  setMenu,
  mobile,
}: {
  mobile?: boolean;
  selectedMenu: MenuType;
  setMenu: React.Dispatch<React.SetStateAction<MenuType>>;
  children?: ReactNode | undefined;
}) => {
  const [mobileProMenu, setProMenu] = useState(false);

  const store = useStore();
  return !mobile ? (
    <>
      <MenuButton
        selected={selectedMenu === 'template'}
        onClick={setMenu.bind(null, 'template')}
        text='Шаблон'
        Icon={NavigationTemplateIcon}
      />
      <MenuButton
        selected={selectedMenu === 'photo'}
        onClick={setMenu.bind(null, 'photo')}
        text='Фото товара'
        Icon={NavigationPhotoIcon}
      />
      <MenuButton
        selected={selectedMenu === 'features'}
        onClick={setMenu.bind(null, 'features')}
        text='Преимущества'
        Icon={NavigationFeaturesIcon}
      />
    </>
  ) : mobileProMenu ? (
    <div className='w-full relative flex'>
      <ProLayout hideIcon>
        <div className='h-[63px] w-full flex justify-between  pb-[10px]'>
          <FooterButton text='Шрифт' Icon={EditorMenuTextIconMobile} />
          <FooterButton text='Фигуры' Icon={EditorMenuFigureIconMobile} />
          <FooterButton text='Объекты' Icon={EditorMenuObjectIconMobile} />
          <FooterButton text='Цвет фона' Icon={EditorMenuColorIconMobile} />
        </div>
      </ProLayout>
      <div>
        <FooterButton
          onClick={setProMenu.bind(null, false)}
          text='Назад'
          Icon={EditorBackIconMobile}
        />
      </div>
    </div>
  ) : (
    <>
      <FooterButton
        selected={selectedMenu === 'template'}
        onClick={setMenu.bind(
          null,
          selectedMenu === 'template' ? 'none' : 'template',
        )}
        text='Шаблон'
        Icon={NavigationTemplateIconMobile}
      />
      <FooterButton
        selected={selectedMenu === 'photo'}
        onClick={setMenu.bind(
          null,
          selectedMenu === 'photo' ? 'none' : 'photo',
        )}
        text='Фото'
        Icon={NavigationPhotoIconMobile}
      />
      <FooterButton
        selected={selectedMenu === 'features'}
        onClick={setMenu.bind(
          null,
          selectedMenu === 'features' ? 'none' : 'features',
        )}
        text='Текст'
        Icon={NavigationFeaturesIconMobile}
      />

      <FooterButton
        pro
        onClick={setProMenu.bind(null, true)}
        text='Дизайн'
        Icon={DesignIconMobile}
      />
      <FooterButton
        onClick={store.editorStore.onSaveBanner}
        text='Скачать'
        Icon={DownloadIconMobile}
      />
    </>
  );
};
