import { observable } from 'mobx';
import { LoadState } from '@@types/loadState';
import { ApiAllProjects, ApiProjectQuiz } from '@@types/quiz';
import { ApiUserPlan } from '@@types/billing';

export class UserDataStore {
  @observable userPlan: ApiUserPlan | null = null;
  @observable
  allProjectsLoader = LoadState.Initial;

  @observable
  allProjectsData: ApiAllProjects | null = null;

  @observable
  recentProjectsLoader = LoadState.Initial;

  @observable
  recentProjects: ApiProjectQuiz[] = [];
}
