import { ApiProjectQuiz } from '@@types/quiz';
import { action, observable } from 'mobx';

export enum BannerFieldsKeys {
  feature = '_feature_',

  header = '_header_',

  features_length = '_features_length_',

  image = '_image_',
}

export enum BannerStylesKeys {
  tranform = '_transform_',
  display = '_display_',
  width = '_width_',
  height = '_height_',
}

export class BannerItem {
  @observable
  image: string | null = require('@assets/images/headset.png');

  @observable
  fields: any = {};

  constructor(projectData?: ApiProjectQuiz) {
    this.fields[BannerFieldsKeys.header] = '';
    if (projectData) {
      this.image = projectData.image_path;
      const f_length = Object.keys(projectData.selected_features).length;
      this.fields[BannerFieldsKeys.header] = projectData.title ?? 'Заголовок';

      Object.keys(projectData.selected_features).forEach((item, i) => {
        this.fields[`${BannerFieldsKeys.feature}${i + 1}`] = `${item}`;
      });

      this.fields = {
        ...this.fields,
        [BannerFieldsKeys.features_length]: f_length,
      };
    }
  }

  @action
  onChangeTextField = (key: BannerFieldsKeys, value: string) => {
    this.fields[key] = value;
  };

  @action
  setImage = (src: string) => {
    this.image = src;
    for (const prop in this.fields) {
      const isInclude = Object.values(BannerStylesKeys).some(
        (substring) =>
          prop.includes(substring) && prop.includes(BannerFieldsKeys.image),
      );
      if (isInclude) {
        delete this.fields[prop];
      }
    }
  };

  @action
  onDeleteFeature = (index: number) => {
    if (this.fields[`${BannerFieldsKeys.feature}${index + 1}`]) {
      delete this.fields[`${BannerFieldsKeys.feature}${index + 1}`];
    }
  };

  @action
  resetFieldsPositions = () => {
    for (const prop in this.fields) {
      const isInclude = Object.values(BannerStylesKeys).some((substring) =>
        prop.includes(substring),
      );
      if (isInclude) {
        delete this.fields[prop];
      }
    }
  };
}
