/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImgGeneratorButton } from '@components/ui/Button/Button';

import { FilterIcon } from '@components/icons/FilterIcon';
import { appColors } from '@config/theme';
import Title from 'antd/es/typography/Title';

import { Space, Typography } from 'antd';
import { useDictionary } from '@hooks/getDictionary';
import { useLogic, useStore } from '@hooks/storeHook';
import { appLanguages } from '@@types/appLanguages';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { AppRoutesPaths } from '@config/navigation';

import { LoadIcon } from '@components/icons/loadIcon';
import { ImgGeneratorLoader } from '@components/ui/ImgGeneratorLoader/ImgGeneratorLoader';
import { LoadState } from '@@types/loadState';
import { AltHeader } from './AltHeader';

export const DevScreen = observer(() => {
  const { Text, Link } = Typography;
  const dictionary = useDictionary();
  const store = useStore();
  const navigate = useNavigate();
  const logic = useLogic();

  function navigateOnLogin() {
    logic.logOut();
  }

  return (
    <div className='w-screen h-screen flex flex-col'>
      <AltHeader />
    </div>
  );
});
