import { appColors } from '@config/theme';

export const DownloadIcon = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M21.5 7C21.5 6.44772 21.0523 6 20.5 6C19.9477 6 19.5 6.44772 19.5 7V23.7703L12.749 19.8834C12.2703 19.6078 11.6589 19.7724 11.3834 20.251C11.1078 20.7297 11.2724 21.3411 11.751 21.6166L19.986 26.358C19.9964 26.3642 20.007 26.3703 20.0177 26.3762L20.5 26.6539L20.9823 26.3762C20.993 26.3703 21.0036 26.3642 21.014 26.358L29.249 21.6166C29.7276 21.3411 29.8922 20.7297 29.6166 20.251C29.3411 19.7724 28.7297 19.6078 28.251 19.8834L21.5 23.7703V7ZM2 33.5C2 32.9477 2.44772 32.5 3 32.5H37C37.5523 32.5 38 32.9477 38 33.5C38 34.0523 37.5523 34.5 37 34.5H3C2.44772 34.5 2 34.0523 2 33.5Z'
      fill={color}
    />
  </svg>
);
