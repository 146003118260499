export const CheckedIcon = () => 
      <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='60' height='60' rx='10' fill='#1B69B1' />
    <path
      d='M45 19.8214L24.375 40.1786L15 30.9253'
      stroke='white'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
