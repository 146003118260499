export const EditorBackIconMobile = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.69401 8.25L3 14.625L9.69401 21'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M3 14.625L26.429 14.625'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
