import { LoadState } from '@@types/loadState';
import { ApiProjectQuiz } from '@@types/quiz';

import { action, computed, observable } from 'mobx';

import { BannerItem } from './banner/BannerItem';
import { toastController } from '@common/toastController';
import html2canvas from 'html2canvas';
import moment from 'moment';

export const GENERATING_WIDTH = 1500;

export const GENERATING_HEIGHT = 2000;

export class EditorStore {
  @observable
  template: number | null = 1;

  @observable
  saving: boolean = false;

  @observable
  featuresLoading: LoadState = LoadState.Initial;

  @observable
  imageLoading: LoadState = LoadState.Initial;

  @observable
  projectData: ApiProjectQuiz | null = null;

  @observable
  banner: BannerItem | null = new BannerItem();

  @observable
  activeElement: string | null = null;

  @observable
  selectedFeatures: string[] = [
    'Отличный подарок',
    'Компактные размеры',
    'Безопасные материалы',
    'Реалистичный дизайн',
  ];

  @computed
  get recommendedFeatures() {
    if (!this.projectData?.recommended_features) return [];
    return Object.keys(this.projectData?.recommended_features).filter(
      (item) => !this.selectedFeatures.includes(item),
    );
  }

  @action
  onSelectFeature = (index: number) => {
    if (this.selectedFeatures.length >= 4) {
      return toastController('Нельзя добавлять больше 4');
    }
    this.selectedFeatures = [
      ...this.selectedFeatures,
      this.recommendedFeatures[index],
    ];
  };

  @action
  onDeleteFeature = (featureIndex: number) => {
    this.selectedFeatures = this.selectedFeatures.filter(
      (_, index) => index !== featureIndex,
    );
    this.banner?.onDeleteFeature(featureIndex);
  };

  @action
  setTemplate = (id: number) => {
    this.template = id;
  };

  @action
  setActiveElement = (element: string | null) => {
    this.activeElement = element;
  };

  @action
  onSaveBanner = () => {
    this.saving = true;
    setTimeout(() => {
      const component = document.getElementById('banner-for-save');
      component &&
        html2canvas(component, {
          scale: 1,
          allowTaint: true,
          useCORS: true,
          width: GENERATING_WIDTH,
          height: GENERATING_HEIGHT,
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = `${
            this.projectData?.title
              ? this.projectData?.title +
                '_' +
                moment().format('DD.MM.YYYY.HH.mm.ss')
              : 'product_' + moment().format('DD.MM.YYYY.HH.mm.ss')
          }.png`; // задаем имя файла
          link.href = imgData;
          link.click(); // автоматически кликаем на ссылку для скачивания файла
        });
      this.saving = false;
    }, 100);
  };
}
