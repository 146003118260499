// import { DeleteIcon } from '@components/icons/DeleteIcon';
import { useStore } from '@hooks/storeHook';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import './styles.css';
import Moveable from 'react-moveable';
import { BannerStylesKeys } from '@store/banner/BannerItem';
import { LoadState } from '@@types/loadState';

export const MoveableImage = observer(
  ({
    src,
    style,
    element_id,
    generating,
  }: {
    style?: CSSProperties;
    generating?: boolean;
    element_id: string;
    src: string;
  }) => {
    const targetRef = React.useRef<HTMLImageElement>(null);
    const {
      editorStore: { banner, imageLoading, activeElement, setActiveElement },
    } = useStore();
    const moveableRef = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [editable, setEditable] = useState(false);

    if (!banner) return;

    const onDrag = action(
      (e: { target: { style: { transform: any } }; transform: any }) => {
        banner.fields[`${element_id}${BannerStylesKeys.tranform}`] =
          e.transform;
      },
    );

    useEffect(() => {
      if (imageLoading === LoadState.Loading) {
        setEditable(false);
      }
    }, [imageLoading]);

    const onResize = action(
      (e: {
        drag: any;
        target: {
          style: {
            maxHeight: string;
            minHeight: string;
            minWidth: string;
            maxWidth: string;
            transform: any;
            width: string;
            height: string;
          };
        };
        width: any;
        height: any;
      }) => {
        e.target.style.width = `${e.width}px`;

        e.target.style.height = `${e.height}px`;
        e.target.style.transform = e.drag.transform;
        banner.fields[`${element_id}${BannerStylesKeys.tranform}`] =
          e.drag.transform;
        banner.fields[`${element_id}${BannerStylesKeys.width}`] =
          `${e.width}px`;
        banner.fields[`${element_id}${BannerStylesKeys.height}`] =
          `${e.height}px`;
        // e.target.style.transform = e.drag.transform;
      },
    );

    return (
      <>
        <img
          id='editor-moveable-image'
          className='group cursor-grab hover:opacity-70 '
          key={src}
          onPointerDown={setActiveElement.bind(null, element_id)}
          onLoad={setEditable.bind(null, true)}
          ref={targetRef}
          style={{
            ...style,
            display:
              banner.fields[`${element_id}${BannerStylesKeys.display}`] ??
              style?.display,
            transform:
              banner.fields[`${element_id}${BannerStylesKeys.tranform}`] ??
              style?.transform,

            width:
              banner.fields[`${element_id}${BannerStylesKeys.width}`] ??
              style?.width,
            height:
              banner.fields[`${element_id}${BannerStylesKeys.height}`] ??
              style?.height,
          }}
          src={src}
        />

        {editable && !generating && activeElement === element_id && (
          <Moveable
            keepRatio
            className='moveable-control-box'
            origin={false}
            // hideDefaultLines={true}
            target={targetRef}
            ref={moveableRef}
            draggable={true}
            onDrag={onDrag}
            onResize={onResize}
            resizable={true}
            rotatable={true}
            onRotate={onDrag}
            rotationPosition={[
              'bottom',
              'left-bottom',
              'right-bottom',
              'left-top',
              'right-top',
            ]}
            renderDirections={['nw', 'ne', 'se', 'sw']}
          />
        )}
      </>
    );
  },
);
