import { CrossCircle } from '@components/icons/CrossCircle';
import { SearchIcon } from '@components/icons/SearchIcon';
import { Form, Input } from 'antd';
import React from 'react';
import { ImgGeneratorLink } from '../ImgGeneratorLink/ImgGeneratorLink';

export type SearchFieldProps = {
  value: string;
  onChange: (value: string) => void;
  rightIcon?: React.ReactNode;
  placeholder?: string;
  isActiveSearchIcon?: boolean;
  disabled?: boolean;
  style?: any;
  className?: string;
  onKeyDown?(evt: React.KeyboardEvent<HTMLInputElement>): void;
  classNameContainer?: string;
  maxLength?: number;
};

export const SearchField = ({
  onKeyDown,
  rightIcon,
  placeholder = 'Search all folders',
  isActiveSearchIcon = false,
  value,
  onChange,
  disabled = false,
  style,
  className,
  classNameContainer,
  maxLength,
}: SearchFieldProps) => {
  function onChangeValue(evt: React.ChangeEvent<HTMLInputElement>) {
    const text = evt.target.value;
    onChange(text);
  }

  function onDelete() {
    onChange('');
  }

  return (
    <Form
      className={`md:min-w-[350px] min-w-[100%] relative ${classNameContainer}`}
    >
      {isActiveSearchIcon && (
        <div
          style={{ top: 'calc(50% - 10px)' }}
          className={'pointer-events-none absolute left-17px z-10'}
        >
          <SearchIcon />
        </div>
      )}
      <Input
        onKeyDown={onKeyDown}
        className={`pr-40px pl-[20px] min-h-[50px] hover:border-blue-200 focus:border-blue border-grey-secondary focus:shadow-none disabled:bg-bg-disabled text-blue font-medium ${className}`}
        style={{ ...style }}
        value={value}
        onChange={onChangeValue}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
      />
      {rightIcon && value.length < 1 && (
        <div
          style={{ top: 'calc(50% - 8px)' }}
          className={'absolute right-17px z-10'}
        >
          {rightIcon}
        </div>
      )}
      {value.length >= 1 && (
        <ImgGeneratorLink
          styles={{ top: '5px' }}
          className={'absolute right-17px z-10'}
          onClick={onDelete}
          forwardIcon={<CrossCircle />}
        />
      )}
    </Form>
  );
};
