import { Store } from '@store/store';
import { observable } from 'mobx';
import { ApiLogic } from './apiLogic';
import { actionAsync, task } from 'mobx-utils';
import { VisibleError } from '@common/visibleError';
import { AuthStore } from '@store/authStore';
import { wait } from '@common/commonUtils';
import { LoadState } from '@@types/loadState';
import { AppRoutesPaths, navigateTo } from '@config/navigation';
import {
  ApiAllProjectsSortType,
  ApiFormData,
  ApiObjectInfoMore,
} from '@@types/quiz';

import { QuizStore } from '@store/quizStore';

import { UserDataStore } from '@store/userDataStore';

import { toastController } from '@common/toastController';
import { __IS_DEV__ } from '@config/devConfig';
import { userFormType } from '@@types/auth';
import { BannerFieldsKeys } from '@store/banner/BannerItem';

export class Logic {
  @observable
  private store: Store;

  private readonly api: ApiLogic;

  private static readonly AuthTokenStoreKey = 'auth-token';
  private static readonly AuthTokenDevStoreKey = 'auth-dev-token';

  constructor(store: Store) {
    this.store = store;

    this.api = new ApiLogic();
    // reaction(
    //   () => this.store.quiz.step,
    //   action((step) => {
    //     if (
    //       !this.store.quiz.projectData?.id ||
    //       !this.store.quiz.projectData?.step
    //     ) {
    //       return;
    //     }
    //     if (
    //       this.store.quiz.projectData?.step > step ||
    //       step < 2) {
    //       return;
    //     }
    //     this.sendProjectStep(this.store.quiz.projectData.id, step);
    //   }),
    // );
  }

  // eslint-disable-next-line arrow-body-style
  private ensureLoggedIn = (): string => {
    // if (!this.store.auth.token) {
    //   navigateTo(AppRoutesPaths.Login, { replace: true });
    //   throw new VisibleError('Пожалуйста, пройдите авторизацию.');
    // }

    // return this.store.auth.token;
    return __IS_DEV__
      ? '3|iI1GHDAmqKNVHcFC0ZP3Qz3vYbDvmJeT3etvQOByb3a1e363'
      : this.store.auth.token ?? '';
  };

  private ensureLoggedOut = () => {
    if (this.store.auth.isLoggedIn) {
      throw new VisibleError('Вы уже авторизованы!');
    }
  };

  loadPersistentData = () => {
    this.loadPersistentToken();
  };

  @actionAsync
  loadPersistentToken = () => {
    const token = localStorage.getItem(
      __IS_DEV__ ? Logic.AuthTokenDevStoreKey : Logic.AuthTokenStoreKey,
    );
    if (!token) {
      return;
    }
    this.store.auth.token = token;
  };

  loadApp = async (): Promise<any> => {
    const urlParams = new URLSearchParams(window.location.search);
    const contextKey = urlParams.get('contextKey');
    if (contextKey) {
      try {
        await this.api.postContextKey(contextKey);
      } catch (e) {
        //
      }
    }
    this.loadPersistentData();

    navigateTo(AppRoutesPaths.Main);
    this.store.rootLoading = LoadState.Success;
    // navigateTo(AppRoutesPaths.Account);
    // this.store.rootLoading = LoadState.Loading;
    // this.loadPersistentData();

    // const timeoutMs = 3000;

    // for (let attempt = 0; attempt < 5; attempt++) {
    //   try {
    //     attempt > 0 && (await wait(timeoutMs));
    //     await this.loadInitialData();
    //     this.store.rootLoading = LoadState.Success;
    //     return true;
    //   } catch (e: unknown) {
    //     console.log(e, 'Failed to load startup info');

    //     this.store.rootLoading = LoadState.Error;

    //     alert('Initial data was not loaded :(');

    //     return false;
    //   }
    // }

    // this.store.rootLoading = LoadState.Error;

    // return false;

    return true;
  };

  loadInitialData = async () => {
    // сначала грузим юзера
    try {
      await this.loadUser();
    } catch (e) {
      this.logOut();
      return;
    }

    //  а потом что то еще,возможно ничего :/
  };

  @actionAsync
  private processLogIn = async (
    token: string,
    isPersistent: boolean,
  ): Promise<void> => {
    if (isPersistent) {
      localStorage.setItem(
        __IS_DEV__ ? Logic.AuthTokenDevStoreKey : Logic.AuthTokenStoreKey,
        token,
      );
    }

    this.store.auth.token = token;

    try {
      await task(this.loadUser());
    } catch (e: unknown) {
      await task(this.logOut());

      throw new VisibleError('Не удалось загрузить данные пользователя', e);
    }
  };

  @actionAsync
  logOut = () => {
    //    наверное внутри логаута будет навигация на авторизацию
    // добавлять сюда позже каждый стор который нужно очистить после логаута ?? или создать для этого другую функцию
    this.store.auth = new AuthStore();
    this.store.quiz = new QuizStore();
    this.store.userData = new UserDataStore();

    localStorage.removeItem(
      __IS_DEV__ ? Logic.AuthTokenDevStoreKey : Logic.AuthTokenStoreKey,
    );

    // Изменить переход на страницу авторизации
    // navigateTo(AppRoutesPaths.Login);
  };

  @actionAsync
  logInByEmail = async (
    email: string,
    password: string,
    isPersistent: boolean,
  ): Promise<boolean> => {
    this.ensureLoggedOut();
    this.store.auth.logInLoading = LoadState.Loading;
    try {
      const token = await task(this.api.login(email, password));
      this.processLogIn(token, isPersistent);

      this.store.auth.logInLoading = LoadState.Success;

      return true;
    } catch (e) {
      console.log(e);
      this.store.auth.logInLoading = LoadState.Error;

      alert('Не удалось авторизоваться');
      return false;
    }
  };

  @actionAsync
  loadUser = async () => {
    if (!this.store.auth.token) throw new VisibleError('No token!');

    const user = await task(this.api.loadUser(this.store.auth.token));

    this.store.auth.user = user;
  };

  @actionAsync
  sendProjectStep = async (id: number, step: number) => {
    const token = this.ensureLoggedIn();
    try {
      await task(this.api.setProjectStep(id, step, token));
      this.getProjectById(id);
    } catch (e) {
      console.log('api step was not updated');
    }
  };

  @actionAsync
  registrationUser = async (form: unknown) => {
    this.store.auth.registrationLoading = LoadState.Loading;
    try {
      await task(this.api.registrationUser(form));

      this.store.auth.registrationLoading = LoadState.Success;
    } catch (e) {
      this.store.auth.registrationLoading = LoadState.Error;
    }
  };

  @actionAsync
  sendEmail = async (form: { email: string }) => {
    try {
      await task(this.api.sendEmail(form));

      return true;
    } catch (e) {
      return false;
    }
  };
  @actionAsync
  sendCodeEmail = async (form: { email: string; code: string }) => {
    this.ensureLoggedOut();
    this.store.auth.logInLoading = LoadState.Loading;
    try {
      const token = await task(this.api.sendCodeEmail(form));

      this.processLogIn(token, true);

      this.store.auth.logInLoading = LoadState.Success;

      return true;
    } catch (e) {
      this.store.auth.logInLoading = LoadState.Error;

      alert('Не удалось авторизоваться');
      return false;
    }
  };

  @actionAsync
  loadUserPlan = async () => {
    const token = this.ensureLoggedIn();

    try {
      const userPlan = await task(this.api.loadUserPlan(token));
      this.store.userData.userPlan = userPlan;
    } catch (e) {
      console.log('userPlan not loaded');
    }
  };

  // оставить
  @actionAsync
  createNewProject = async () => {
    const token = this.ensureLoggedIn();

    this.store.rootLoading = LoadState.Loading;
    const form = new FormData();
    try {
      const project = await task(this.api.createProjectQuiz(form, token));

      this.store.rootLoading = LoadState.Success;

      this.store.editorStore.projectData = project;
    } catch (e) {
      this.store.rootLoading = LoadState.Error;
    }
  };

  // @actionAsync
  // initiateProjectById = async (id: number, withNavigate?: boolean) => {
  //   const token = this.ensureLoggedIn();

  //   this.store.rootLoading = LoadState.Loading;
  //   try {
  //     const project = await task(this.api.getProjectById(id, token));

  //     this.store.rootLoading = LoadState.Success;

  //     this.store.quiz = new QuizStore(project);
  //     withNavigate && navigateTo(AppRoutesPaths.Quiz);
  //   } catch (e) {
  //     this.store.rootLoading = LoadState.Error;
  //     return false;
  //   }
  // };

  // оставить
  @actionAsync
  getProjectById = async (id: number) => {
    console.log('getting started');

    const token = this.ensureLoggedIn();

    try {
      const project = await task(this.api.getProjectById(id, token));
      this.store.editorStore.projectData = project;

      console.log('getting finished');
      return project;
    } catch (e) {
      return false;
    }
  };

  @actionAsync
  sendSubscribe = async (form: {
    email: string;
    phone: string;
    name: string;
  }) => {
    try {
      await task(this.api.sendSubscribeForm(form));
      return true;
    } catch (e) {
      return false;
    }
  };

  // Quiz Action
  @actionAsync
  updatingProjectQuiz = async (form: ApiFormData, withoutLoading?: boolean) => {
    console.log('updating started');
    const token = this.ensureLoggedIn();
    if (!withoutLoading) {
      this.store.quiz.commonLoading = LoadState.Loading;
    }

    try {
      const response = await task(this.api.createProjectQuiz(form, token));
      this.store.quiz.projectData = response;
      if (!withoutLoading) {
        this.store.quiz.commonLoading = LoadState.Success;
      }
      console.log('updating finished');
      return true;
    } catch (e) {
      if (!withoutLoading) {
        this.store.quiz.commonLoading = LoadState.Error;
      }
      return false;
    }
  };

  @actionAsync
  getAllProjects = async (
    type: ApiAllProjectsSortType,
    page: number,
    count: number = 8,
  ) => {
    const token = this.ensureLoggedIn();
    this.store.userData.allProjectsLoader = LoadState.Loading;
    try {
      const data = await task(
        this.api.getAllProjects(type, page, count, token),
      );
      console.log(data);
      this.store.userData.allProjectsData = data;
      this.store.userData.allProjectsLoader = LoadState.Success;
    } catch (e) {
      this.store.userData.allProjectsLoader = LoadState.Error;
    }
  };

  @actionAsync
  getRecentProjects = async () => {
    const token = this.ensureLoggedIn();
    this.store.userData.recentProjectsLoader = LoadState.Loading;
    try {
      const projects = await task(this.api.getRecentProjects(token));
      console.log(projects);
      this.store.userData.recentProjects = projects;
      this.store.userData.recentProjectsLoader = LoadState.Success;
    } catch (e) {
      this.store.userData.recentProjectsLoader = LoadState.Error;
    }
  };
  // оставить
  @actionAsync
  getInfoByIdWithPending = async (id: number) => {
    const token = this.ensureLoggedIn();
    const timeoutMs = 1000;
    await wait(3000);
    for (let attempt = 0; attempt < 60; attempt++) {
      try {
        await wait(timeoutMs);
        const result = await this.api.getProjectInfoById(id, token);
        console.log('result', attempt, result);
        if (result.type === 0 || result.type === 3) {
          throw new VisibleError('Status Error Occured,try again');
        }
        if (result.type === 2) {
          return result.info;
        }
      } catch (e: unknown) {
        return null;
      }
    }

    throw new VisibleError('Timeout has expired');
  };
  // оставить
  @actionAsync
  generateFeatures = async (form: ApiFormData) => {
    const token = this.ensureLoggedIn();
    this.store.editorStore.featuresLoading = LoadState.Loading;

    try {
      const preloadedData = await task(this.api.createProjectQuiz(form, token));
      await this.getInfoByIdWithPending(preloadedData.id);
      this.store.editorStore.featuresLoading = LoadState.Success;
      const result = await this.getProjectById(preloadedData.id);
      if (!result || result.recommended_features?.length === 0) {
        toastController('Не удалось подобрать преимущества');
        return false;
      } else {
        const newArray: string[] = [];
        for (let i = 0; i <= 3; i++) {
          if (Object.keys(result.recommended_features)[i]) {
            newArray.push(Object.keys(result.recommended_features)[i]);
          }
        }
        this.store.editorStore.selectedFeatures = newArray;
      }
      if (this.store.editorStore.banner)
        this.store.editorStore.banner.fields[BannerFieldsKeys.header] =
          result.title;
      return result;
    } catch (e: any) {
      if (e.response.status === 429) {
        toastController('Превышен лимит запросов!');
      }
      if (e.response.status === 422) {
        toastController('Возможно вы вставили одинаковые ссылки!');
      }
      this.store.editorStore.featuresLoading = LoadState.Error;
      return false;
    }
  };

  // оставить
  @actionAsync
  uploadImage = async (form: ApiFormData) => {
    const token = this.ensureLoggedIn();
    this.store.editorStore.imageLoading = LoadState.Loading;

    try {
      const response = await task(this.api.createProjectQuiz(form, token));
      this.store.editorStore.projectData = response;

      this.store.editorStore.imageLoading = LoadState.Success;

      return this.store.editorStore.projectData;
    } catch (e) {
      this.store.editorStore.imageLoading = LoadState.Error;
      return false;
    }
  };

  @actionAsync
  removeImg = async (id: number) => {
    const token = this.ensureLoggedIn();
    this.store.quiz.imageLoading = LoadState.Loading;

    try {
      const response = await task(this.api.removingImgQuiz(id, token));

      this.store.quiz.projectData = response;
      this.store.quiz.imageLoading = LoadState.Success;
    } catch (e) {
      this.store.quiz.imageLoading = LoadState.Error;
    }
  };
  @actionAsync
  loadMoreInfoProject = async (form: ApiObjectInfoMore) => {
    const token = this.ensureLoggedIn();

    this.store.quiz.commonLoading = LoadState.Loading;

    try {
      form.project_id = this.store.quiz.projectData?.id ?? 0;
      await task(this.api.updateMoreInfoProjectQuiz(form, token));

      this.store.quiz.commonLoading = LoadState.Success;
    } catch (e) {
      this.store.quiz.commonLoading = LoadState.Error;
    }

    return this.getProjectById(Number(this.store.quiz.projectData?.id));
  };

  @actionAsync
  changePreviewTitle = async (title: string) => {
    try {
      // const response = await task(this.api.postUpdatingBullet(id,text, token)) пока нету api для смены title и description

      console.log(title);
    } catch (e) {
      /* empty */
    }
  };
  @actionAsync
  changePreviewDescription = async (description: string) => {
    try {
      // const response = await task(this.api.postUpdatingBullet(id,text, token)) пока нету api для смены title и description

      console.log(description);
    } catch (e) {
      /* empty */
    }
  };
  // Quiz Action

  // account

  @actionAsync
  getTypesReentro = async () => {
    const token = this.ensureLoggedIn();
    this.store.auth.typeRentryLoading = LoadState.Loading;

    try {
      const response = await task(this.api.getTypesReentro(token));
      this.store.auth.typeRentry = response;
      this.store.auth.typeRentryLoading = LoadState.Success;
    } catch (e) {
      this.store.auth.typeRentryLoading = LoadState.Error;
    }
  };
  @actionAsync
  getTypesSendCode = async () => {
    const token = this.ensureLoggedIn();
    this.store.auth.typeSendCodeLoading = LoadState.Loading;

    try {
      const response = await task(this.api.getTypeSendCode(token));
      this.store.auth.typeSendCode = response;
      this.store.auth.typeSendCodeLoading = LoadState.Success;
    } catch (e) {
      this.store.auth.typeSendCodeLoading = LoadState.Error;
    }
  };
  @actionAsync
  getAppLanguage = async () => {
    const token = this.ensureLoggedIn();
    this.store.auth.languageAppLoading = LoadState.Loading;

    try {
      const response = await task(this.api.getLanguageApp(token));
      this.store.auth.languageApp = response;
      this.store.auth.languageAppLoading = LoadState.Success;
    } catch (e) {
      this.store.auth.languageAppLoading = LoadState.Error;
    }
  };
  @actionAsync
  updateAccount = async (form: userFormType) => {
    const token = this.ensureLoggedIn();

    try {
      await task(this.api.postUpdatingAccount(form, token));
      this.loadUser();
      toastController('Data was updated');
      return true;
    } catch (e: any) {
      toastController(e.response.data.message);
      return false;
    }
  };

  @actionAsync
  addFavoriteTemplate = async (id: number) => {
    const token = this.ensureLoggedIn();

    try {
      await task(this.api.addFavoriteTemplate(id, token));

      return true;
    } catch (e) {
      return false;
    }
  };
  @actionAsync
  removeFavoriteTemplate = async (id: number) => {
    const token = this.ensureLoggedIn();

    try {
      await task(this.api.removeFavoriteTemplate(id, token));

      return true;
    } catch (e) {
      return false;
    }
  };

  // account
}
