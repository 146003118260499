import { DesktopComponent } from './DeckstopComponent';
import useScreenSize from '@hooks/getScreenSize';

import { useTemplateMenu } from '@hooks/templateMenuHooks';

export const TemplateMenu = () => {
  const [selectedMenu, setMenu, renderRightSide, renderText] =
    useTemplateMenu();
  const { sm } = useScreenSize();

  return !sm ? (
    <DesktopComponent
      setMenu={setMenu}
      selectedMenu={selectedMenu}
      text={renderText()}
    >
      {renderRightSide()}
    </DesktopComponent>
  ) : null;
};
