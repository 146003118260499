import { ImgGeneratorButton } from '@components/ui/Button/Button';
import { QuizLayoutContainer } from '@components/ui/QuizLayoutContainer/QuizLayoutContainer';
import { AppRoutesPaths } from '@config/navigation';
import useScreenSize from '@hooks/getScreenSize';
import { useLogic } from '@hooks/storeHook';

import { Typography } from 'antd';
import { action } from 'mobx';

import React from 'react';
import { useNavigate } from 'react-router-dom';

export const HomePage = () => {
  const navigate = useNavigate();
  const logic = useLogic();

  const createProject = action(() => {
    navigate(AppRoutesPaths.Editor);
    logic.createNewProject();
  });
  const { sm } = useScreenSize();
  return (
    <QuizLayoutContainer header contentClassName='!bg-inherit'>
      <div className='flex flex-col sm:flex-row w-full h-full max-w-[1440px] ml-auto mr-auto  overflow-hidden pt-[95px]'>
        <div className='flex flex-col sm:flex-1 jusify-center items-center ml-[-40px]'>
          <Typography.Text className='text-blue text-2lg sm:text-xm max-w-[600px] font-black uppercase'>
            Создание продающих карточек с инфографикой которые удваивают продажи
          </Typography.Text>
          <Typography.Text className='text-grey text-sm sm:text-xl max-w-[600px] mt-[20px]'>
            Наши нейросети проанализируют конкурентов и создадут красивую
            инфографику автоматически.
          </Typography.Text>
          <ImgGeneratorButton
            onClick={createProject}
            className='max-w-[600px] mt-[80px] hidden sm:flex'
          >
            Создать карточку бесплатно
          </ImgGeneratorButton>
        </div>
        <div className='flex flex-col sm:flex-1 relative sm:overflow-hidden'>
          <img
            style={{
              width: sm ? '100%' : '100%',
              maxHeight: '130%',
              height: 'auto',
            }}
            className='relative pt-20px sm:pt-[0px] width-full sm:absolute object-contain'
            src={require('@assets/images/main-preview.webp')}
          />
        </div>
        <ImgGeneratorButton
          onClick={createProject}
          styles={{ marginLeft: '20px', width: 'calc(100% - 40px)' }}
          className='sm:hidden mt-[20px]'
        >
          Создать карточку бесплатно
        </ImgGeneratorButton>
      </div>
    </QuizLayoutContainer>
  );
};
