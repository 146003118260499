import { appColors } from '@config/theme';

export const EditorMenuFigureIcon = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_426_567)'>
      <rect
        x='4'
        y='4'
        width='12'
        height='12'
        rx='1'
        stroke={color}
        stroke-width='2'
      />
      <rect
        x='24'
        y='24'
        width='12'
        height='12'
        rx='6'
        stroke={color}
        stroke-width='2'
      />
      <path
        d='M15.382 36H4.61803L10 25.2361L15.382 36Z'
        stroke={color}
        stroke-width='2'
      />
      <path
        d='M23 15L28.3356 9.28327C28.7309 8.8597 29.4024 8.8597 29.7977 9.28327L31.1356 10.7167C31.5309 11.1403 32.2024 11.1403 32.5977 10.7167L37 6'
        stroke={color}
        stroke-width='2'
        stroke-linecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_426_567'>
        <rect width='40' height='40' rx='2' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
