/**
 * Type with keys of `T`, but with values of type `TValue`.
 */
export type ValueMap<T, TValue = unknown> = {
  [K in keyof T]: TValue;
};

export type Nullable<T> = T | null;

export type NullableMap<T> = {
  [K in keyof T]: Nullable<T[K]>;
};

export type NonNullableMap<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};

export type SomeNonNullable<T, TKeys extends keyof T> = Omit2<T, TKeys> &
  NonNullableMap<Pick<T, TKeys>>;

export type StringKeyOf<T> = keyof T & string;

export type KeyForValueOf<
  T,
  TKey extends StringKeyOf<T>,
  TValue,
> = T[TKey] extends TValue ? TKey : never;

/**
 * Omit that preserves discriminated unions.
 * See https://github.com/microsoft/TypeScript/issues/31501
 */
export type Omit2<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

/**
 * Omit that constraints K to keys of T and preserves discriminated unions.
 */
export type OmitStrict<T, K extends keyof T> = Omit2<T, K>;

export type Replace<
  TSource,
  TReplacement extends { [K in keyof TSource]?: any },
> = Omit2<TSource, keyof TReplacement> & TReplacement;

/**
 * Omits from `T` properties with keys mathing `TShape`.
 */
export type OmitShape<T, TShape> = Omit<T, keyof TShape>;

/**
 * Keys of required properties of `T`.
 */
export type RequiredKeys<T> = {
  [K in keyof T]-?: object extends Pick<T, K> ? never : K;
}[keyof T];

/**
 * Keys of optional properties of `T`.
 */
export type OptionalKeys<T> = {
  [K in keyof T]-?: object extends Pick<T, K> ? K : never;
}[keyof T];

/**
 * Picks required properties from `T`.
 */
export type PickRequired<T> = Pick<T, RequiredKeys<T>>;

/**
 * Picks optional properties from `T`.
 */
export type PickOptional<T> = Pick<T, OptionalKeys<T>>;

/**
 * Omits required properties from `T`.
 */
export type OmitRequired<T> = Omit2<T, RequiredKeys<T>>;

/**
 * Omits optional properties from `T`.
 */
export type OmitOptional<T> = Omit2<T, OptionalKeys<T>>;

export type StringMap<TValue = never> = {
  [key: string]: TValue;
};

export type KeyValuePair<TKey, TValue> = {
  key: TKey;
  value: TValue;
};

export type NumericEnum<T extends number> = {
  [value in T]: string;
};

/**
 * @example
 * EnumKey<typeof MyEnum>
 */
export type EnumKey<TEnum extends EnumConstraint<TEnum>> = keyof TEnum;

/**
 * @example
 * EnumValue<typeof MyEnum>
 */
export type EnumValue<TEnum extends EnumConstraint<TEnum>> =
  TEnum[EnumKey<TEnum>];

export type EnumConstraint<TEnum> = {
  [value: string]: TEnum[keyof TEnum] | string;
};

/**
 * NoInfer - prevents inference of generic type parameters.
 */
export type NI<T> = [T][T extends never ? never : 0];

/**
 * `never` if `T` is `never`, otherwise, `unknown`.
 */
export type NeverIfNever<T> = T extends never ? never : unknown;

/**
 * Infers `true` if `T` is equivalent to `U`, otherwise `false`.
 *
 * Example:
 *
 * const test1: Equals&lt;string, string&gt; = true // ok
 *
 * const test2: Equals&lt;string, number&gt; = true // error at compile time
 */
export type Equals<T, U> = [T] extends [U]
  ? [U] extends [T]
    ? true
    : false
  : false;

/**
 * Identity function, ensures that the given `value` has type `T`.
 */
export const exact = <T = never>(value: NI<T>): NI<T> => value;

export const keysOf = (value: object) =>
  Object.keys(value) as Array<keyof object>;

export const valuesOf = (value: object) =>
  Object.values(value) as Array<[keyof object]>;
