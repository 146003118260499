import './Footer.css';
import { Layout } from 'antd';

import { MobileComponent } from '../TemplateMenu/MobileComponent';
import { useTemplateMenu } from '@hooks/templateMenuHooks';
import { useEffect } from 'react';
import { MenuButtonsComponent } from '../TemplateMenu/MenuButtonsComponent';
import { Variants, motion } from 'framer-motion';
import { SmallCloseIcon } from '@components/icons/new-editor/SmallCloseIcon';

const variants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3, // 1 секунда
      ease: 'easeInOut',
    },
  },
  closed: {
    opacity: 0,
    y: '100%',
    transition: {
      duration: 0.3, // 1 секунда
      ease: 'easeOut',
    },
  },
};

export const QuizFooter = () => {
  const { Footer } = Layout;

  const [selectedMenu, setMenu, renderRightSide, renderText] =
    useTemplateMenu();

  useEffect(() => setMenu('none'), []);

  return (
    <>
      <Footer
        id='footer'
        style={{ boxShadow: '0px -5px 50px 0px #3987CF26' }}
        className='w-full h-[63px] fixed bottom-0 !z-[3000] flex items-start !p-0 overflow-hidden'
      >
        <div className='bg-mobile-background h-[63px] w-full flex justify-between  pb-[10px] px-[20px]'>
          <MenuButtonsComponent
            mobile
            selectedMenu={selectedMenu}
            setMenu={setMenu}
          />
        </div>
      </Footer>
      <motion.div
        initial='closed'
        animate={selectedMenu !== 'none' ? 'open' : 'closed'}
        variants={variants}
        style={{
          bottom: '62px',
          height: 'calc(80% - 64px - 64px)',
          width: '100%',
          left: '50%',
          translateX: '-50%',
          boxShadow: '0px -5px 50px 0px #3987CF50',
        }}
        className='h-screen absolute bg-white z-[2999] pt-[20px] pb-[60px] overflow-scroll'
      >
        <div
          onClick={setMenu.bind(this, 'none')}
          className='absolute top-[35px] right-[20px] cursor-pointer'
        >
          <SmallCloseIcon />
        </div>
        <MobileComponent
          text={renderText()}
          setMenu={setMenu}
          selectedMenu={selectedMenu}
        >
          {renderRightSide()}
        </MobileComponent>
      </motion.div>
      <div
        onClick={setMenu.bind(this, 'none')}
        style={{ display: selectedMenu !== 'none' ? 'block' : 'none' }}
        className='absolute w-screen h-screen z-[2998]'
      />
    </>
  );
};
