import { appColors } from '@config/theme';
import { Button } from 'antd';
import './Button.css';

export type ImgGeneratorButtonProps = {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  styles?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  onClick?:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLButtonElement>)
    | undefined;
  props?: any;
  htmlType?: string;
  accountblue?: boolean;
  white?: boolean;
};

export const ImgGeneratorButton = ({
  children,
  icon,
  styles,
  disabled,
  className,
  loading,
  onClick,
  props,
  htmlType,
  white,
}: ImgGeneratorButtonProps) => {
  const bgColor = disabled
    ? appColors['blue-200']
    : white
      ? appColors.white
      : appColors.blue;

  const hoverColor = white
    ? 'hover:!bg-blue'
    : disabled
      ? 'hover:!bg-blue-100'
      : 'hover:!bg-blue-600';

  const textColor = white ? appColors.blue : appColors.white;

  return (
    <Button
      htmlType={htmlType}
      {...props}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      type={'primary'}
      style={{
        ...{
          background: bgColor,
          color: textColor,
          '-webkit-border-radius': '10px',
          fontWeight: 'normal',
          boxShadow: white && '0px 0px 40px 0px #3987CF26',
        },
        ...styles,
      }}
      // почему-то кнопка при загрузке  становится уже,поэто му параметры ширины нужно использовать для кнопки с !important
      className={`flex justify-center items-center shadow-none !outline-none  !drop-shadow-none min-w-[227px] w-full min-h-[62px] h-[62px] border-0 hover:outline outline-[5px] outline-blue-100 ${hoverColor} active:!bg-blue-600 hover:!text-white font-helveticabold ${className}`}
      icon={icon}
    >
      {!loading && children}
    </Button>
  );
};
