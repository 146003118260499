import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';
import './FormInput.css';
import { useState } from 'react';

export const FormInput = ({
  props,
  title,
  label,
  rules,
  classNameForm,
  placeholder = 'Enter text',
  defaultValue,
  backIcon,
  forwardIcon,
  containerInput,
  classNameInput,
  blue,
  value,
  onChange,
}: {
  props?: any;
  classNameForm?: string;
  title: string;
  label?: string;
  rules: Rule[];
  containerInput?: string;
  placeholder?: string;
  defaultValue?: string;
  backIcon?: React.ReactNode;
  forwardIcon?: React.ReactNode;
  classNameInput?: string;
  blue?: boolean;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}) => {
  const borderColor = blue
    ? 'hover:border-blue focus:border-blue focus:shadow-none disabled:bg-bg-disabled '
    : '';

  const [inputValue, setInputValue] = useState('');

  const handleChange = (e: any) => {
    onChange?.(e);
    setInputValue(e.target.value);
  };

  return (
    <Form.Item
      className={`!mb-0 ${classNameForm}`}
      {...props}
      name={title}
      label={label}
      rules={rules}
    >
      <div className={` relative  ${containerInput}`}>
        <div className='absolute top-[12px] left-[16px]'>{backIcon}</div>
        <label
          className={`absolute top-[9px] left-[20px] transition-all duration-300 z-[3000] ${
            // eslint-disable-next-line no-constant-condition
            inputValue ? 'text-xs text-placeholder-grey' : 'hidden'
          }`}
        >
          {placeholder}
        </label>
        <Input
          onChange={handleChange}
          className={`p-[20px] ${
            inputValue ? 'pb-[5px]' : ''
          } min-h-[62px] text-base placeholder:text-placeholder-grey ${
            backIcon ? 'p-[20px]' : ''
          } ${forwardIcon ? 'pr-[60px]' : ''} ${borderColor} ${classNameInput}`}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={value}
        />
        <div className='absolute top-[12px] right-[16px]'>{forwardIcon}</div>
      </div>
    </Form.Item>
  );
};
