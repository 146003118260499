import Altlogo from '@assets/images/altlogo.svg';
import BoxIcon from '@assets/images/box.svg';
import Bag from '@assets/images/bag.svg';
import Chart from '@assets/images/chart.svg';
import Cloud from '@assets/images/cloud.svg';
import Printer from '@assets/images/printer.svg';
import Brush from '@assets/images/brush.svg';
import Question from '@assets/images/question.svg';
import Avatar from '@assets/images/MuiAvatar.svg';
import { PropsWithChildren } from 'react';

export const AltHeader = () => (
  <div className='w-full bg-blue h-[80px] flex flex-row px-[10px] '>
    <div className='w-[121px] h-[80px] flex items-center mr-auto pr-[10px]'>
      <img src={Altlogo} />
    </div>
    <Button>
      <img src={BoxIcon} />
      <text className='text-white  hidden lg:block'>Товары</text>
    </Button>
    <Button>
      <img src={Bag} />
      <text className='text-white  hidden lg:block'>Обороты</text>
    </Button>
    <Button>
      <img src={Chart} />
      <text className='text-white  hidden lg:block'>Заказы</text>
    </Button>

    <Button>
      <img src={Cloud} />
      <text className='text-white  hidden lg:block'>Синхронизация</text>
    </Button>
    <Button>
      <img src={Printer} />
      <text className='text-white  hidden lg:block'>Печать</text>
    </Button>
    <Button>
      <img src={Brush} />
      <text className='text-white  hidden lg:block min-w-[128px]'>
        Инфографика для маркетплейсов
      </text>
    </Button>
    <Button>
      <img src={Question} />
      <text className='text-white  hidden lg:block'>Инструкция</text>
    </Button>
    <Button>
      <text className='text-white  max-w-[126px] '>
        Администратор user5@mpsklad.ru
      </text>
      <img src={Avatar} />
    </Button>
  </div>
);

const Button = ({ children }: PropsWithChildren) => (
  <div className='h-[80px] px-[15px] py-[25px] flex justify-center items-center gap-[10px] cursor-pointer hover:bg-blue-600'>
    {children}
  </div>
);
