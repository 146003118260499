/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeleteIcon } from '@components/icons/DeleteIcon';
import { appColors } from '@config/theme';
import { useStore } from '@hooks/storeHook';
import { BannerStylesKeys } from '@store/banner/BannerItem';
import { action } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useEffect, useState } from 'react';
import { PropsWithChildren } from 'react';
import Moveable from 'react-moveable';

export const MoveableElement = observer(
  ({
    children,
    style,
    element_id,
    onDelete,
  }: PropsWithChildren & {
    style?: CSSProperties;
    element_id: string;
    onDelete?: () => void;
  }) => {
    const targetRef = React.useRef<HTMLDivElement>(null);
    const {
      editorStore: { banner, activeElement, setActiveElement },
    } = useStore();
    const [isDragging, setIsDragging] = useState(false);

    if (!banner) return;

    const onDrag = action(
      (e: { target: { style: { transform: any } }; transform: any }) => {
        if (isDragging) {
          banner.fields[`${element_id}${BannerStylesKeys.tranform}`] =
            e.transform;
        }
      },
    );

    const ondelete = action(() => {
      onDelete
        ? onDelete()
        : (banner.fields[`${element_id}${BannerStylesKeys.display}`] = 'none');
    });

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      if (!targetRef.current) return;
      // const focusedElement = document.activeElement
      // @ts-ignore
      document.activeElement?.blur?.();

      setActiveElement(element_id);

      const textArea = targetRef.current.querySelector('textarea');

      const timeoutId = setTimeout(() => {
        setIsDragging(true); // Set dragging state after a long press
      }, 100); // Adjust timeout as needed (e.g., 500ms for a longer press)

      const handleMouseUp = () => {
        clearTimeout(timeoutId);
        setIsDragging(false);

        if (textArea) {
          textArea.focus(); // Focus on text area if clicked within timeout
          textArea.setSelectionRange(
            textArea.value.length,
            textArea.value.length,
          );
        }

        document.removeEventListener('pointerup', handleMouseUp);
      };

      document.addEventListener('pointerup', handleMouseUp);
    };

    const active = activeElement === element_id;

    const onBlur = () => {
      if (active) {
        setActiveElement(null);
      }
    };

    return (
      <>
        <div
          id='editor-moveable-item'
          className='group !cursor-grab relative'
          style={{
            ...style,
            transform:
              banner.fields[`${element_id}${BannerStylesKeys.tranform}`],
            display:
              banner.fields[`${element_id}${BannerStylesKeys.display}`] ??
              style?.display,
            outline: active ? `6px solid ${appColors['blue-600']}` : '',
          }}
          ref={targetRef}
          onPointerDown={handleMouseDown}
          onBlur={onBlur}
        >
          <div
            onPointerDown={ondelete}
            className='group-hover:block hidden absolute bottom-[10px] right-[10px] z-[33999] cursor-pointer'
          >
            <DeleteIcon />
          </div>

          {children}
        </div>
        <Moveable
          hideThrottleDragRotateLine={true}
          hideChildMoveableDefaultLines={true}
          origin={false}
          hideDefaultLines={true}
          target={targetRef}
          draggable={true}
          onDrag={onDrag}
        />
      </>
    );
  },
);
