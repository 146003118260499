import { appLanguages } from '@@types/appLanguages';
import { action, observable } from 'mobx';

import { AuthStore } from './authStore';
import { LoadState } from '@@types/loadState';
import { QuizStore } from './quizStore';

import { UserDataStore } from './userDataStore';
import { EditorStore } from './EditorStore';

export class Store {
  @observable
  language: appLanguages = appLanguages.EN;

  @observable
  rootLoading = LoadState.Initial;

  @observable
  proModalOpened = false;

  @observable
  auth = new AuthStore();

  @observable
  quiz = new QuizStore();

  @observable
  editorStore = new EditorStore();

  @observable
  userData = new UserDataStore();

  @action
  changeLanguage = (language: appLanguages) => {
    this.language = language;
  };

  @action
  setProModal = (value: boolean) => (this.proModalOpened = value);
}
