import { appColors } from '@config/theme';

export const NavigationFeaturesIcon = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='27'
    height='27'
    viewBox='0 0 27 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13.5 1L17.3625 9.22818L26 10.5557L19.75 16.9569L21.225 26L13.5 21.7282L5.775 26L7.25 16.9569L1 10.5557L9.6375 9.22818L13.5 1Z'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
