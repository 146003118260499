export const EditorMenuColorIconMobile = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.16462 13.335C6.58423 16.2683 7.60238 18.8123 9.29757 20.5812C11.0498 22.4097 13.3185 23.2123 15.0052 22.9512C15.0531 22.9437 15.1304 22.918 15.1976 22.7682C15.2718 22.6026 15.268 22.4057 15.1903 22.2532C13.5992 19.1261 15.8922 15.5016 19.3513 15.5016H22.4646C22.7344 15.5016 22.9967 15.2647 22.9998 14.9493C22.9795 10.0347 18.5093 6.15794 13.3632 7.15965M24.9998 14.9507C24.9803 8.7944 19.3787 3.95057 12.9801 5.19668C9.09724 5.95451 5.96829 9.07565 5.20265 12.9468C3.75732 20.2516 10.3473 25.697 15.3122 24.9275C16.9216 24.6775 17.7107 22.7946 16.9724 21.3454C16.07 19.5719 17.3591 17.5016 19.3513 17.5016H22.4646C23.8631 17.5016 24.9959 16.3453 24.9998 14.9507ZM7.16462 13.335C7.77237 10.2621 10.2769 7.76236 13.3632 7.15965Z'
      fill='#3987CF'
    />
    <path
      d='M12.2857 16C12.2857 16.9468 11.5182 17.7143 10.5714 17.7143C9.62466 17.7143 8.85714 16.9468 8.85714 16C8.85714 15.0532 9.62466 14.2857 10.5714 14.2857C11.5182 14.2857 12.2857 15.0532 12.2857 16Z'
      fill='#3987CF'
    />
    <path
      d='M13.5714 11.2857C13.5714 12.2325 12.8039 13 11.8571 13C10.9104 13 10.1429 12.2325 10.1429 11.2857C10.1429 10.3389 10.9104 9.57143 11.8571 9.57143C12.8039 9.57143 13.5714 10.3389 13.5714 11.2857Z'
      fill='#3987CF'
    />
    <path
      d='M18.2857 10.5714C18.2857 11.5182 17.5182 12.2857 16.5714 12.2857C15.6247 12.2857 14.8571 11.5182 14.8571 10.5714C14.8571 9.62466 15.6247 8.85715 16.5714 8.85715C17.5182 8.85715 18.2857 9.62466 18.2857 10.5714Z'
      fill='#3987CF'
    />
  </svg>
);
