export const EditorMenuTextIconMobile = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M24.3976 3.75V3H22.8976V3.75V6.36294H20.2845H19.5345V7.86294H20.2845H22.8976V10.4759V11.2259H24.3976V10.4759V7.86295H27.0104H27.7604V6.36295H27.0104H24.3976V3.75ZM5.74999 7.84879C5.05964 7.84879 4.49999 8.40844 4.49999 9.0988V24.9821C4.49999 25.6724 5.05964 26.2321 5.75 26.2321H21.6333C22.3236 26.2321 22.8833 25.6724 22.8833 24.9821V13.901C22.8833 13.4867 23.219 13.151 23.6333 13.151C24.0475 13.151 24.3833 13.4867 24.3833 13.901V24.9821C24.3833 26.5008 23.152 27.7321 21.6333 27.7321H5.75C4.23121 27.7321 2.99999 26.5008 2.99999 24.9821V9.0988C2.99999 7.58001 4.23121 6.34879 5.74999 6.34879H16.8311C17.2453 6.34879 17.5811 6.68458 17.5811 7.09879C17.5811 7.51301 17.2453 7.84879 16.8311 7.84879H5.74999ZM12.9 11.0406L8 22.5534H9.82047L11.1362 19.45H16.5637L17.8794 22.5534H19.7L14.8 11.0406H12.9ZM13.85 12.7675L16.0703 18H11.6296L13.85 12.7675Z'
      fill='#3987CF'
    />
  </svg>
);
