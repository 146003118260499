export interface ApiProjectQuiz {
  id: number;
  name: string;
  age_id: number;
  sex_id: number;
  description: string;
  image_path: string | null;
  created_at: string;
  updated_at: string;
  recommended_features?: any;
  selected_features?: any;
  folder_id: number;
  info: ApiMoreInfoProject;
  platform_id: number | null;
  user_id: number;
  template_id: number;
  folter_id: number;
  country: string;
  keywords: string[] | null;
  bullets: ApiProjectBullets[];
  title: string;
  step: number;
}

export enum ApiAllProjectsSortType {
  recent,
  revelant,
  saved,
  popularity,
}

export interface ApiGetInfoById {
  type: number;
  info: ApiMoreInfoProject | null;
}
export interface ApiAllProjects {
  data: ApiProjectQuiz[];
  current_page: number;
  last_page: number;
  total: number;
  from: number;
  to: number;
}
export interface ApiProjectImg {
  created_at: string;
  id: number;
  is_main: number;
  path: string;
  project_id: number;
  updated_at: string;
}
export interface ApiProjectInfo {
  project_id: number;
  id: number;
  unique_text: string | null;
  main_feature: string | null;
  package: string | null;
  color: string | null;
  size: string | null;
  material: string | null;
  box_inside: string | null;
  important: string | null;
}
export interface ApiProjectBullets {
  project_id: number;
  id: number;
  text: string | null;
}

export interface ApiMoreInfoProject {
  id: number;
  project_id: number;
  unique_text: string;
  main_feature: string;
  package: string;
  color: string;
  material: string;
  size: string;
  box_inside: string;
  important: string;
}

export interface ApiObjectInfoMore {
  project_id: number;
  product: string;
  feature: string;
  packaging: string;
  color: string;
  material: string;
  size: string;
  box: string;
  details: string;
}

export interface ApiPlatformQuiz {
  id: number;
  name: string;
  img: string;
}

export interface ApiTargetQuiz {
  sex: {
    '1': string;
    '2': string;
    '3': string;
  };
  age: {
    '1': string;
    '2': string;
    '3': string;
  };
}

export interface ApiCountryQuiz {
  [key: string]: string;
}

export interface ApiPostFeatures {
  selected_features: string[];
  id: number;
}

export interface ApiPostKeywords {
  keywords: string[];
  id: number;
}

export interface ApiPostLinks {
  competitors: string[];
  id: number;
}

export type ApiFormData =
  | FormData
  | ApiPostFeatures
  | ApiPostKeywords
  | ApiPostLinks;

export interface ApiBulletPoints {
  project_id: number;
  text: string;
  updated_at: string;
  created_at: string;
  id: number;
}

export type ProgressArrayType = {
  bulletPoints: ProgressType;
  title: ProgressType;
  description: ProgressType;
};

export type ProgressType = {
  title: string;
  progress: number;
};

export type BulletType = {
  text: string;
  id?: number;
};
