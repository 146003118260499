import { exact } from '@common/tsUtils';
import { AppContext } from '@context/appContexts';
import { Logic } from '@logic/logic';
import { Store } from '@store/store';
import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { antConf } from './theme';
import { useAnyEffect } from '@hooks/commonHooks';
import { RootLoader } from '@components/ui/RootLoader/RootLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toastStyles.css';
import ruRu from 'antd/locale/ru_RU';
import { ProModal } from '@components/ui/ProLayout/ProModal';
export const withConfigs = (WrappedComponent: () => JSX.Element) => () => {
  const [appContext] = useState(() => {
    const store = new Store();
    return exact<AppContext>({
      store,
      logic: new Logic(store),
    });
  });

  useAnyEffect(async () => {
    await appContext.logic.loadApp();
  });

  return (
    <BrowserRouter>
      <ConfigProvider locale={ruRu} theme={antConf}>
        <AppContext.Provider value={appContext}>
          <ToastContainer
            autoClose={3000}
            theme='colored'
            position={'top-center'}
            hideProgressBar
          />
          <RootLoader>
            <ProModal />
            <WrappedComponent />
          </RootLoader>
        </AppContext.Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
};
