import { appColors } from '@config/theme';

export const NavigationTemplateIconMobile = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.98529 22.5C4.98529 22.5 6.352 19.7447 8.34147 19.7447C10.3309 19.7447 11.3476 22.059 13.5525 22.059C15.7558 22.059 18.2283 16.2555 20.7261 16.2555C23.2205 16.2555 25.0147 20.2799 25.0147 20.2799'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M11.8336 10.1223C11.8336 11.5698 10.6604 12.7447 9.21121 12.7447C7.76371 12.7447 6.59056 11.5698 6.59056 10.1223C6.59056 8.67481 7.76371 7.5 9.21121 7.5C10.6604 7.50168 11.8336 8.67481 11.8336 10.1223Z'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
