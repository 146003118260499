import { useCallback, useState } from 'react';
import { ChooseTemplateMenu } from '@components/quiz/ChooseTemplateMenu';
import { UploadPhotoComponent } from '@components/quiz/UploadPhotoComponent';
import { EnterLinksScreen } from '@components/quiz/EnterLinksComponent';
export type MenuType = 'template' | 'photo' | 'features' | 'none';

export const useTemplateMenu = () => {
  const [selectedMenu, setMenu] = useState<MenuType>('template');

  const renderRightSide = useCallback(() => {
    switch (selectedMenu) {
      case 'template':
        return <ChooseTemplateMenu />;
      case 'photo':
        return <UploadPhotoComponent />;
      case 'features':
        return <EnterLinksScreen />;
      case 'none':
        return <></>;
    }
  }, [selectedMenu]);

  const renderText = useCallback(() => {
    switch (selectedMenu) {
      case 'template':
        return 'Выберите шаблон';
      case 'photo':
        return 'Загрузите фото товара';
      case 'features':
        return 'Вставьте ссылки на конкурентов ';
      case 'none':
        return '';
    }
  }, [selectedMenu]);
  return [selectedMenu, setMenu, renderRightSide, renderText] as const;
};
