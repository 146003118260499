import { appColors } from '@config/theme';

export const SearchIcon = ({
  color = appColors['blue-200'],
}: {
  color?: string;
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.7383 12.8978H14.6651L19.6393 17.8925C20.1202 18.3732 20.1202 19.1588 19.6393 19.6395C19.1583 20.1202 18.3723 20.1202 17.8913 19.6395L12.9054 14.6565V13.7303L12.5886 13.402C10.9462 14.8089 8.7055 15.5359 6.32401 15.1372C3.06265 14.5862 0.458262 11.866 0.0593914 8.58315C-0.550646 3.62364 3.62577 -0.550324 8.58819 0.0593566C11.873 0.457994 14.5947 3.06086 15.1461 6.3203C15.545 8.7004 14.8176 10.9398 13.4098 12.5812L13.7383 12.8978ZM2.3468 7.62185C2.3468 10.5413 4.70483 12.8979 7.62597 12.8979C10.5471 12.8979 12.9051 10.5413 12.9051 7.62185C12.9051 4.70242 10.5471 2.34577 7.62597 2.34577C4.70483 2.34577 2.3468 4.70242 2.3468 7.62185Z'
      fill={color}
    />
  </svg>
);
