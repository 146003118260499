import { Typography } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import './LoadFileImgGenerator.css';
import { RcFile } from 'antd/es/upload';
import { UploadIcon } from '@components/icons/UploadIcon';
import { ImgGeneratorButton } from '../Button/Button';
export const LoadFileImgGenerator = ({
  beforeUpload,
  classNameDragger,
}: {
  beforeUpload(file: RcFile): void;
  classNameDragger?: string;
}) => {
  const { Text } = Typography;
  return (
    <Dragger
      beforeUpload={beforeUpload}
      showUploadList={false}
      className={`min-h-[62px] bg-blue-50 sm:min-h-[457px]  h-[100%] w-[100%] p-[0px] m-[0px] dragger-upload rounded-[14px] sm:border-dashed gap-[16px] border-blue border-[2px] ${classNameDragger}`}
    >
      <div className='hidden sm:flex w-full h-full gap-[20px] flex flex-col justify-center items-center'>
        <UploadIcon />

        <Text className='text-lg text-blue w-[237px]'>
          Перенесите файл в эту область или
        </Text>
        <div className='w-[144px] h-[62px] bg-blue-100 rounded-[10px] flex justify-center items-center font-helveticabold text-blue'>
          Выберите
        </div>
        {/* <Text className='text-lg'>Max. file sixe 20 MB</Text> */}
      </div>
      <ImgGeneratorButton className='sm:hidden'>Загрузить</ImgGeneratorButton>
    </Dragger>
  );
};
