  

   // eslint-disable-next-line
  export const normalizePhone = (value: string): string => {
      return value.split('')
        .filter(symbol => '0123456789'.includes(symbol))
        .map((digit) => digit)
        .filter((_, index) => index < 11)
        .join('')
  
  
    }
  
  export const formatPhone =
    //@ts-ignore
    (value: string): string => {
 
      const firstDigit = value[0];
      // eslint-disable-next-line
      if (!!!value) {
        return value
      }
      if (firstDigit === '8') {
        return formatPhone('7' + value.substring(1))
      }
      if (firstDigit !== '7') {
        return formatPhone('7' + value)
      }
      return value.split('')
        .map((digit, index) => {
          switch (index) {
            case 0:
  
              return '+7'
  
            case 1:
              return ' (' + digit
  
            case 4:
              return ') ' + digit
  
            case 7:
              return '-' + digit
  
  
            case 9:
              return '-' + digit
  
            default:
              return digit
          }
        }).join('')
    }
  
 
  
  