export const EditorMenuFigureIconMobile = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='3'
      y='4'
      width='8.29412'
      height='8.29412'
      rx='1'
      stroke='#3987CF'
      stroke-width='2'
    />
    <rect
      x='17.7059'
      y='18.7059'
      width='8.29412'
      height='8.29412'
      rx='4.14706'
      stroke='#3987CF'
      stroke-width='2'
    />
    <path
      d='M10.6761 27H3.61803L7.14706 19.942L10.6761 27Z'
      stroke='#3987CF'
      stroke-width='2'
    />
    <path
      d='M16.7059 11.8235L20.4356 7.8274C20.8309 7.40382 21.5024 7.40382 21.8977 7.8274L22.4944 8.46673C22.8898 8.89031 23.5612 8.89031 23.9565 8.46673L27 5.20589'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
    />
  </svg>
);
