import { MenuType } from '@hooks/templateMenuHooks';
import { PropsWithChildren } from 'react';

export const MobileComponent = ({
  children,
  text,
}: PropsWithChildren & {
  text: string;
  selectedMenu: MenuType;
  setMenu: React.Dispatch<React.SetStateAction<MenuType>>;
}) => (
  <div className='flex flex-col w-full gap-[20px] p-[20px] bg-white'>
    <text className='h-[36px] font-bold text-lg'>{text}</text>
    {children}
  </div>
);
