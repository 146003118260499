import { ThemeConfig } from 'antd';

export const appColors = {
  'blue-50': 'rgba(57, 135, 207, 0.05)',
  'blue-100': 'rgba(57, 135, 207, 0.10)',
  'blue-200': 'rgba(57, 135, 207, 0.25)',
  'blue-500': 'rgba(57, 135, 207, 0.50)',

  blue: '#3987CF',
  'blue-600': '#1B69B1',
  'blue-700': 'rgba(57, 135, 207, 0.7)',
  'grey-bg': '#F5F5F5',
  'grey-secondary': '#e2e2e2',
  grey: '#888888',

  black: '#1F1F1F',
  'black-acc': '#1D1D1D',
  green: '#4CAF50',
  'placeholder-grey': '#696A80',

  'screen-background': '#E8F4FF',
  'mobile-background': '#F1F6FC',

  orange: '#FF4500',

  'error-bg': '#F8D7DA',
  'error-text': '#721C24',

  white: '#FFF',
};
//https://ant.design/docs/react/customize-theme
export const antConf: ThemeConfig = {
  components: { Tag: { fontFamily: 'Helvetica-bold' } },
  token: {
    colorPrimary: appColors.blue,
    colorFillSecondary: appColors['blue-200'],
    colorBorder: appColors['blue'],
    colorPrimaryBorderHover: appColors['blue'],
    colorTextPlaceholder: appColors['placeholder-grey'],
    //нет в дизайне
    colorSuccess: 'green',
    colorError: appColors['error-bg'],
    colorLink: appColors.blue,
    colorLinkHover: appColors['blue-200'],
    colorWarning: 'orange',
    colorText: appColors.black,
    fontFamily:
      "Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
    fontSize: 16,
    fontSizeHeading1: 36,
    fontSizeHeading2: 24,
    fontSizeHeading3: 18,
    fontSizeHeading4: 16,
    fontSizeHeading5: 14,
    borderRadius: 10,
    colorTextDisabled: appColors['blue-100'],
    colorBgContainerDisabled: appColors['blue-200'],
    opacityLoading: 1,
    colorBgLayout: appColors['screen-background'],
  },
};
