export const CloseSmallIcon = () => (
  <svg
    width='11'
    height='11'
    viewBox='0 0 11 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.4375 1.28125L1.56244 9.15631'
      stroke='white'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M1.5625 1.28125L9.43756 9.15631'
      stroke='white'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
