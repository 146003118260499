/* eslint-disable @typescript-eslint/no-unused-vars */
import { appColors } from '@config/theme';

export const NavigationPhotoIconMobile = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.9073 22.8917V22.8917C5.74936 22.8917 4 21.1424 4 18.9844V18.1181C4 15.9709 5.73737 14.2302 7.88457 14.2302V14.2302C7.89531 14.2302 7.9073 14.2215 7.9073 14.2107V14.2107C7.9073 10.8497 10.593 8.09321 13.9532 8.0197L14.8536 8L15.6094 8.01688C18.97 8.09197 21.6552 10.8493 21.6552 14.2107V14.2107C21.6552 14.2215 21.6639 14.2302 21.6746 14.2302V14.2302C23.8218 14.2302 25.5625 15.9709 25.5625 18.1181V18.9844C25.5625 21.1424 23.8131 22.8917 21.6552 22.8917V22.8917'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
    />
    <path
      d='M16.9375 17.3396L14.7812 15.0755L12.625 17.3396'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M14.7812 15.0755V23'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
