import { QuizLayoutContainer } from '@components/ui/QuizLayoutContainer/QuizLayoutContainer';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@hooks/storeHook';
import { TemplateMenu } from '../ui/TemplateMenu/TemplateMenu';
import { EditorMenu } from '@components/editor/EditorMenu';
import useScreenSize from '@hooks/getScreenSize';

export const EditorPage = observer(() => {
  const {
    editorStore: { projectData },
  } = useStore();

  const { sm } = useScreenSize();
  console.log(projectData);
  return (
    <QuizLayoutContainer footer header>
      <div className='flex flex-row justify-center items-center  gap-[20px] h-full w-full  sm:p-[20px] '>
        {!sm && <TemplateMenu />}
        <EditorMenu />
      </div>
    </QuizLayoutContainer>
  );
});
