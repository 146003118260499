import { memo } from 'react';

export const BannerMarks = memo(({ typeId }: { typeId?: number | null }) =>
  typeId ? (
    <>
      {typeId === 7 && (
        <div
          style={{
            position: 'absolute',
            left: '-77px',
            top: '38px',
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='54'
            height='54'
            viewBox='0 0 54 54'
            fill='none'
          >
            <circle cx='27' cy='27' r='26.5' fill='white' stroke='#7E2A2A' />
            <circle cx='27' cy='27' r='22.5' stroke='#7E2A2A' />
          </svg>
        </div>
      )}
      {typeId === 5 && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            left: `-${50}px`,
            top: `${5}px`,
          }}
          className={``}
        >
          {' '}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={36}
            height={65}
            viewBox='0 0 34 65'
            fill='none'
          >
            <path
              d='M34 32.5L0 0V18.6306L14.5096 32.5L0 46.3694V65L34 32.5Z'
              fill='#C0245E'
            />
          </svg>
        </div>
      )}
      {typeId === 8 && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='26'
          height='31'
          viewBox='0 0 26 31'
          fill='none'
        >
          <path d='M26 15.5L0.5 30.2224L0.5 0.777568L26 15.5Z' fill='#840000' />
        </svg>
      )}

      {typeId === 4 && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            left: `-${50}px`,
            top: `${20}px`,
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={35}
            height={35}
            viewBox='0 0 35 35'
            fill='none'
          >
            <circle cx='17.5' cy='17.5' r='17.5' fill='#000C48' />
          </svg>
        </div>
      )}
      {(typeId === 3 || typeId === 6) && (
        <div
          style={{
            position: 'absolute',

            display: 'flex',
            top: '-10px',
            left: `-${21}px`,
          }}
        >
          <svg
            width={55}
            height={55}
            viewBox='0 0 49 55'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <ellipse
              cx='24.1562'
              cy='27.5'
              rx='24.1562'
              ry='27.5'
              fill='#000C48'
            />
            <path
              d='M37.7715 28L18.0073 40.9904L18.0073 15.0096L37.7715 28Z'
              fill='white'
            />
          </svg>
        </div>
      )}
    </>
  ) : null,
);
