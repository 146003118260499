import { BlackRingActiveIcon } from '@components/icons/BlackRingActiveIcon';
import { Typography } from 'antd';

export type CardImgProps = {
  onClick?: () => void;
  img?: string;
  isActive?: boolean;
  title?: string;
  classNameContainer?: string;
  icon?: React.ReactNode;
};

export const CardImg = ({
  onClick,
  img,
  isActive = false,
  title,
  classNameContainer,
  icon,
}: CardImgProps) => {
  const classNameActive = isActive ? 'border-blue-200' : 'border-transparent';
  const { Text } = Typography;

  return (
    <div
      onClick={onClick}
      className={`cursor-pointer relative bg-white overflow-hidden min-w-[150px] min-h-[143px] sm:min-w-[143px] sm:min-h-[109px] max-w-[150px] max-h-[143px] sm:max-w-[143px] sm:max-h-[109px] rounded-[10px] border-solid border-blue-700 border-[1px] flex justify-center items-center ${classNameActive} ${classNameContainer}`}
    >
      {img && (
        <img
          width={'95%'}
          height={'95%'}
          className='object-contain'
          src={img}
        />
      )}
      {icon && icon}
      {title && (
        <Text className='first-letter:uppercase text-base text-black text-center'>
          {title}
        </Text>
      )}
      {isActive && (
        <div className='absolute min-w-[40px] min-h-[40px] right-[5px] top-[5px] rounded-[5px] max-h-[18px] bg-blue-500'>
          <BlackRingActiveIcon />
        </div>
      )}
    </div>
  );
};
