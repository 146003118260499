export const CrossCircle = () => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M27.6612 13.753C28.0518 13.3625 28.0518 12.7293 27.6612 12.3388C27.2707 11.9483 26.6376 11.9483 26.247 12.3388L20 18.5858L13.753 12.3388C13.3625 11.9483 12.7293 11.9483 12.3388 12.3388C11.9483 12.7293 11.9483 13.3625 12.3388 13.753L18.5858 20L12.3388 26.247C11.9483 26.6376 11.9483 27.2707 12.3388 27.6612C12.7293 28.0518 13.3625 28.0518 13.753 27.6612L20 21.4142L26.2471 27.6612C26.6376 28.0518 27.2708 28.0518 27.6613 27.6612C28.0518 27.2707 28.0518 26.6376 27.6613 26.247L21.4142 20L27.6612 13.753Z'
      fill='#3987CF'
    />
  </svg>
);
