import useScreenSize from '@hooks/getScreenSize';
import { Layout } from 'antd';
import { QuizFooter } from '../Footer/QuizFooter';

import { SecondHeader } from '../Header/SecondHeader';
import { __MOYSKLAD__ } from '@config/devConfig';

export type LayoutContainerProps = {
  header?: boolean;
  children: React.ReactNode;
  footer?: boolean;
  sideMenu?: boolean;
  className?: string;
  styles?: React.CSSProperties;
  contentClassName?: string;
  contentStyles?: React.CSSProperties;
};

const { Content } = Layout;

export const QuizLayoutContainer = (props: LayoutContainerProps) => {
  const { sm } = useScreenSize();

  const showHeader = props.header && !__MOYSKLAD__;

  const showFooter = sm && props.footer;
  return (
    <Layout
      className={`w-full min-h-full max-w-screen overflow-hidden fixed sm:relative ${props.className}`}
      style={props.styles}
    >
      {/* Подставить сюда header из props */}
      {/* <Header style={headerStyle}>Header</Header> */}
      {showHeader && <SecondHeader />}
      <Layout
        style={{
          height: `calc(100vh - ${showHeader ? (sm ? 64 : 100) : 0}px - ${
            showFooter ? 0 : 0
          }px)`,
        }}
        hasSider
      >
        {/* Контент всегда children min-h надо чем-то было перезаписать ,потому что при маленьких размерах с паддингами какая то беда была*/}
        {/* pb внизу на мобилах нужен в основном на андроид потому что там адресная строка занимает место а 100vh считается  без ее учета */}
        <Layout
          style={{
            height: `calc(100vh - ${showHeader ? (sm ? 64 : 100) : 0}px - ${
              showFooter ? 0 : 0
            }px)`,
            ...props.contentStyles,
          }}
          className={` w-full overflow-y-scroll pb-[184px] sm:pb-[0px] px-[20px] sm:px-[0px] ${props.contentClassName}`}
        >
          <Content className='min-h-fit'>{props.children}</Content>
        </Layout>
      </Layout>

      {showFooter && <QuizFooter />}
    </Layout>
  );
};
