import { DevScreen } from '@components/forDev/DevScreen';

// import { HomePage } from '@components/home/HomePage/HomePage';
import { NavigateFunction, NavigateOptions } from 'react-router-dom';

import { HomePage } from '@components/home/HomePage/HomePage';
import { EditorPage } from '@components/editor/EditorPage';

let navigateFunc: NavigateFunction | null = null;

export const setNavigate = (navigate: NavigateFunction) => {
  navigateFunc = navigate;
};

export const navigateTo = (route: string, options?: NavigateOptions) => {
  navigateFunc?.(route, options);
};

export enum AppRoutesPaths {
  Main = '/',
  Editor = '/editor',
  Dev = '/dev',
  Alt = '/alt',
}

export type AppRoutesType = {
  name: string;
  path: string;
  element: JSX.Element;
  childs?: AppRoutesType;
}[];

export const AppRoutes: AppRoutesType = [
  {
    name: 'Главная',
    path: AppRoutesPaths.Main,
    element: <HomePage />,
  },
  {
    name: 'Editor',
    path: AppRoutesPaths.Editor,
    element: <EditorPage />,
  },
  {
    name: 'Dev',
    path: AppRoutesPaths.Dev,
    element: <DevScreen />,
  },
  {
    name: 'Alt',
    path: AppRoutesPaths.Alt,
    element: <DevScreen />,
  },
];
