import { observer } from 'mobx-react-lite';

import { useStore } from '@hooks/storeHook';

import { Typography } from 'antd';

import useScreenSize from '@hooks/getScreenSize';
import { BannerViewItem } from '@components/editor/BannerViewItem';

import { ImgGeneratorLoader } from '@components/ui/ImgGeneratorLoader/ImgGeneratorLoader';
import { EditorMenuTextIcon } from '@components/icons/new-editor/editorMenuText';
import { EditorMenuButton } from './EditorMenuButton';
import { EditorMenuFigureIcon } from '@components/icons/new-editor/editorMenuFigure';
import { EditorMenuObjectIcon } from '@components/icons/new-editor/editorMenuObject';
import { EditorMenuColorIcon } from '@components/icons/new-editor/editorMenuColor';
import { DownloadIcon } from '@components/icons/new-editor/downloadIcon';
import { appColors } from '@config/theme';
import { ProLayout } from '@components/ui/ProLayout/ProLayout';
import { GENERATING_HEIGHT, GENERATING_WIDTH } from '@store/EditorStore';
import { __MOYSKLAD__ } from '@config/devConfig';

export const EditorMenu = observer(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { Text } = Typography;

  const { sm, laptop, width } = useScreenSize();
  const {
    editorStore: { template, onSaveBanner, saving },
  } = useStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  return (
    <ImgGeneratorLoader loading={saving} withoutOpacity>
      <div
        className={`w-full h-full  sm:w-[670px] sm:max-h-[540px] laptop:max-h-[740px] minidesktop:max-h-[840px] rounded sm:gap-[20px] flex flex-col`}
      >
        <text className='hidden sm:block h-[36px] font-bold text-xl'>
          Редактируйте элементы и перемещайте{' '}
        </text>
        <div
          style={{
            height: saving
              ? undefined
              : sm
                ? '100%'
                : !laptop
                  ? '662px'
                  : '362px',
          }}
          className='flex flex-col  justify-center items-center rounded w-full sm:bg-grey-bg '
        >
          <BannerViewItem
            // position={position}
            key={
              saving
                ? GENERATING_WIDTH
                : sm
                  ? 280 + Number(template)
                  : 440 + Number(template)
            }
            width={
              saving ? GENERATING_WIDTH : sm ? width - 20 : !laptop ? 420 : 280
            }
            height={
              saving
                ? GENERATING_HEIGHT
                : sm
                  ? ((width - 20) / 3) * 4
                  : !laptop
                    ? 562
                    : 362
            }
            editing={true}
            typeId={template}
            generating={saving}
          />
        </div>
        <div className='w-full h-[102px] gap-[20px] hidden sm:flex mt-auto'>
          {!__MOYSKLAD__ && (
            <ProLayout>
              <div className='w-full sm:w-[532px] h-[102px] sm:bg-white rounded flex flex-row'>
                <EditorMenuButton text='Текст' Icon={EditorMenuTextIcon} />
                <EditorMenuButton text='Фигуры' Icon={EditorMenuFigureIcon} />
                <EditorMenuButton text='Обьекты' Icon={EditorMenuObjectIcon} />
                <EditorMenuButton text='Цвет фона' Icon={EditorMenuColorIcon} />
              </div>
            </ProLayout>
          )}
          <div
            onClick={onSaveBanner}
            className='ml-auto w-[118px] h-[102px] rounded bg-blue hover:bg-blue-600 hidden sm:flex flex-col gap-[5px] justify-center items-center cursor-pointer'
          >
            <DownloadIcon color={appColors.white} />
            <text className='text-white font-bold text-sm font-helvetica'>
              Скачать
            </text>
          </div>
        </div>
      </div>
    </ImgGeneratorLoader>
  );
});
