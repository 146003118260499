import { observer } from 'mobx-react-lite';


import { ImgGeneratorButton } from '../ui/Button/Button';

import { useStore } from '@hooks/storeHook';
import { ProLayout } from '@components/ui/ProLayout/ProLayout';
import { CheckedIcon } from '@components/icons/new-editor/CheckedIcon';
import { BannerConfig } from '@config/bannersConfig';

export const ChooseTemplateMenu = observer(() => {
  const {
    editorStore: { template, setTemplate },
  } = useStore();

  return (
    <>
      <div className='flex flex-row w-full justify-center flex-wrap gap-[20px] mb-[20px]'>
{Object.keys(BannerConfig).map((item) => (
    <div className='relative cursor-pointer'>
      {template === Number(item) && (
        <div className='absolute bg-blue-700 w-[130px] h-[162px] sm:w-[193px] sm:h-[241px] rounded-[10px] flex justify-center items-center'>
         <CheckedIcon key={template}/>
        </div>
      )}
      <img
        onClick={setTemplate.bind(null, Number(item))}
        className='w-[130px] h-[162px] sm:w-[193px] sm:h-[241px] rounded-[10px]'
        src={require(`@assets/images/banners/${item}.webp`)}
      />
    </div>))}
      </div>
      <ProLayout>
        <div className='flex flex-col sm:flex-row w-full sm:justify-between  pb-[20px]'>
          <ImgGeneratorButton className='' white>
            100 + шаблонов в PRO версии
          </ImgGeneratorButton>
        </div>
      </ProLayout>
    </>
  );
});

