import CSS from 'csstype';

export enum templateTypeKeys {
  first = 1,
  second = 2,
  third = 3,
  fourth = 4,
  fifth = 5,
  six = 6,
}

export enum templateItemKeys {
  headersContainer = 'headersContainer',
  header = 'header',
  subHeader = 'subHeader',
  image = 'image',
  container = 'container',
  indexFeatureContainer = 'indexFeatureContainer',
  featureIndex = 'featureIndex',
  featureContainer = 'featureContainer',
  featureText = 'featureText',
  featureDescText = 'featureDescText',
}

type TemplateBasicStyles = {
  [key: string]: {
    [key in templateItemKeys]: CSS.Properties;
  };
};

export const basicStyles: TemplateBasicStyles = {
  '1': {
    headersContainer: {
      position: 'absolute',
      left: `${0}px`,
      top: `${138}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      textAlign: 'center',

      color: '#FFF',
      fontFamily: 'Montserrat-bold',
      fontSize: `${126}px`,

      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    subHeader: {
      width: '100%',
      textAlign: 'left',

      color: '#FFF',
      fontFamily: 'Montserrat',
      fontSize: `${65}px`,

      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },

    image: {
      position: 'absolute',
      bottom: '271px',
      right: `73px`,
      // minHeight: '1200px',
      width: 'auto',
      height: '1200px',
      // maxWidth: '1300px',
    },
    container: {
      position: 'absolute',
      left: `${78}px`,
      top: `${540}px`,
      height: `calc(100% - ${668}px)`,
      width: `${815}px`,

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: '63px',
    },
    indexFeatureContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '30px',
      paddingLeft: '21px',
      background:
        'linear-gradient(90deg, #150A37 1.12%, rgba(21, 10, 55, 0.00) 100%)',
      gap: `${62}px`,
    },
    featureContainer: { display: 'flex', flexDirection: 'column' },
    featureIndex: {
      color: '#150A37 ',
      fontFamily: 'Inter-bold',
      fontSize: `${176}px`,
      fontStyle: 'normal',
      fontWeight: '800',
      cursor: 'inherit',
      textShadow:
        '2px 2px 2px #FFFFFF, -2px 2px 2px #FFFFFF, 2px -2px 2px #FFFFFF, -2px -2px 2px #FFFFFF',
    },
    featureText: {
      color: '#FFF',
      fontFamily: 'Montserrat',
      fontSize: `${55}px`,
      maxWidth: '486px',
      fontWeight: '600',
    },
    featureDescText: {
      color: '#FFF',
      fontFamily: 'Montserrat',
      fontSize: `${48}px`,
      fontWeight: '300',
    },
  },
  '2': {
    headersContainer: {
      position: 'absolute',
      left: `${0}px`,
      top: `${58}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: `100%`,
      textAlign: 'center',

      color: 'rgba(0, 12, 72, 1)',
      fontFamily: 'Montserrat-bold',
      fontSize: `${126}px`,

      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    subHeader: {
      width: `100%`,
      textAlign: 'left',

      color: 'rgba(0, 12, 72, 1)',
      fontFamily: 'Montserrat',
      fontSize: `${73}px`,
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },

    image: {
      position: 'absolute',
      bottom: '62px',
      left: `50%`,
      transform: `translate(-50%, 0)`,

      height: '900px',
      width: 'auto',
      // maxWidth: '1000px',
    },
    container: {
      position: 'absolute',
      left: `${68}px`,
      top: `${347}px`,
      height: '217px',
      width: `calc(100% - ${68}px)`,

      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: '',
      rowGap: '91px',
    },
    indexFeatureContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: `${40}px`,
      minWidth: '650px',
      width: '650px',
    },
    featureContainer: {
      minWidth: '613px',
      width: '613px',
      padding: '52px',
      paddingTop: '23px',
      paddingBottom: '23px',
      paddingRight: '63px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: `${50}px`,

      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.00) 100%)',
    },
    featureIndex: {
      color: '#000',
      fontFamily: 'Inter-bold',
      fontSize: `${127}px`,
      fontStyle: 'normal',
      fontWeight: '700',
      cursor: 'inherit',
      lineHeight: 'normal',
    },
    featureText: {
      color: '#000C48',
      fontFamily: 'Montserrat',
      fontSize: `${55}px`,
      fontWeight: '500',
      minWidth: '431px',
      maxWidth: '450px',
    },
    featureDescText: {
      color: '#000C48',
      fontFamily: 'Montserrat',
      fontSize: `${45}px`,
      fontWeight: '300',
    },
  },
  '3': {
    headersContainer: {
      position: 'absolute',
      left: `${87}px`,
      bottom: `${50}px`,
      width: '1326px',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    header: {
      width: '100%',
      textAlign: 'center',

      color: 'rgba(0, 12, 72, 1)',
      fontFamily: 'Montserrat-extra-bold',
      fontSize: `${148}px`,
      fontStyle: 'normal',
      fontWeight: '900',
      lineHeight: 'normal',
    },
    subHeader: {
      width: '100%',
      textAlign: 'right',

      color: 'rgba(30, 60, 84, 1)',
      fontFamily: 'Montserrat',
      fontSize: `${22}px`,

      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
    },

    image: {
      position: 'absolute',
      bottom: '840px',
      left: `50%`,
      transform: `translate(-50%, 0)`,
      height: '1129px',
      width: 'auto',
      // maxWidth: '1400px',
    },
    container: {
      position: 'absolute',
      left: `${97}px`,
      bottom: `${341}px`,

      width: 'calc(100% - 194px)',
      rowGap: `${38}px`,
      columnGap: '82px',
      height: '500px',
      display: 'flex',
      flexDirection: 'row',

      justifyContent: 'center',
      alignContent: 'flex-end',

      flexWrap: 'wrap',
    },
    indexFeatureContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      position: 'relative',
      width: `610px`,
      borderRadius: `${30}px`,
      border: `1.5px solid #000C48`,
      paddingLeft: `${55}px`,
      paddingRight: `${55}px`,
    },
    featureContainer: {
      // overflow: 'hidden',

      // width: `${250 }px`,

      minHeight: `${40}px`,
    },
    featureIndex: {
      display: 'none',
    },
    featureDescText: {
      display: 'none',
    },
    featureText: {
      color: '#10135A',
      fontFamily: 'Montserrat',
      fontSize: `${48}px`,
      fontWeight: '400',
      height: '100%',
      // overflow: 'hidden',
    },
  },
  '4': {
    headersContainer: {
      position: 'absolute',

      top: `${133}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      textAlign: 'center',

      color: 'rgba(30, 60, 84, 1)',
      fontFamily: 'Montserrat-extra-bold',
      fontSize: `${114}px`,

      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    subHeader: {
      width: '100%',
      textAlign: 'center',
      color: 'rgba(0, 12, 72, 1)',
      fontFamily: 'Montserrat',
      fontSize: `${22}px`,

      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },

    image: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%) rotate(15deg)`,
      // maxHeight: `${1050}px`,
      height: '1050px',
      width: `auto`,
      // maxWidth: '1200px',
    },
    container: {
      position: 'absolute',
      left: `${80}px`,
      bottom: `${147}px`,

      width: `calc(100% - ${60}px)`,
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '50px',
    },
    indexFeatureContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
    },
    featureContainer: {
      display: 'flex',

      flexDirection: 'row',
      alignItems: 'start',
    },
    featureIndex: {
      display: 'none',
    },
    featureText: {
      color: '#10135A',
      fontFamily: 'Montserrat',
      fontSize: `${47}px`,
      fontWeight: '600',
    },
    featureDescText: {
      display: 'none',
      color: '#FFF',
      fontFamily: 'Montserrat',
      fontSize: `${12}px`,
      fontWeight: '300',
    },
  },
  '5': {
    headersContainer: {
      position: 'absolute',

      top: `${54}px`,
      left: `${0}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: '100%',
      textAlign: 'center',

      color: '#C0245E',
      textShadow: `${15}px ${9}px ${4}px #FFD6E5`,
      fontFamily: 'Montserrat-extra-bold',
      fontSize: `${225}px`,

      fontStyle: 'normal',
      fontWeight: '900',
      lineHeight: 'normal',
    },
    subHeader: {
      width: '95%',
      textAlign: 'left',

      color: '#C0245E',
      textShadow: `${15}px ${9}px ${4}px #FFD6E5`,
      fontFamily: 'Montserrat-bold',
      fontSize: `${33}px`,

      fontStyle: 'normal',
      fontWeight: '900',
      lineHeight: 'normal',
    },

    image: {
      position: 'absolute',
      top: '617px',
      left: '50%',
      transform: `translate(-50%, 0)`,
      width: `auto`,
      // maxWidth: '65%',
      height: `942px`,
    },
    container: {
      position: 'absolute',
      left: `${184}px`,
      bottom: '91px',

      width: '100%',
      columnGap: '124px',
      rowGap: `${20}px`,

      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    indexFeatureContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      width: `540px`,
      position: 'relative',
    },
    featureContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    featureIndex: {
      display: 'none',
    },
    featureDescText: {
      color: '#FFF',
      fontFamily: 'Montserrat',
      fontSize: `${48}px`,
      fontWeight: '600',
      display: 'none',
    },
    featureText: {
      color: 'black',
      fontFamily: 'Montserrat',
      fontSize: `${48}px`,
      fontWeight: '400',
    },
  },
  '6': {
    headersContainer: {
      position: 'absolute',
      left: `${0}px`,
      top: `${82}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    header: {
      width: '100%',
      textAlign: 'center',

      color: '#10135A',
      fontFamily: 'Montserrat-extra-bold',
      fontSize: `${160}px`,

      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    subHeader: {
      width: '100%',
      textAlign: 'right',

      color: '#10135A',
      fontFamily: 'Montserrat',
      fontSize: `${22}px`,

      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },

    image: {
      position: 'absolute',
      bottom: '408px',
      left: `90px`,
      height: 'auto',
      width: '751px',
      // maxWidth: '751px',
    },
    container: {
      position: 'absolute',
      right: '91px',
      top: `${577}px`,
      height: `calc(100% - ${239}px)`,
      width: `${509}px`,
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '66px',
    },
    indexFeatureContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-start',
      position: 'relative',
      borderRadius: `${38}px`,
      border: `${1.5}px solid #000C48`,
    },
    featureContainer: {
      // overflow: 'hidden',

      paddingLeft: `${55}px`,
      paddingRight: `${55}px`,
    },
    featureIndex: {
      display: 'none',
    },
    featureDescText: {
      display: 'none',
    },
    featureText: {
      width: '427px',
      color: '#10135A',
      fontFamily: 'Montserrat',
      fontSize: `${48}px`,
      fontWeight: '400',
      height: '100%',
      // overflow: 'hidden',
    },
  },
  '7': {
    headersContainer: {
      position: 'absolute',
      left: `${79}px`,
      top: `${143}px`,
      background: 'linear-gradient(90deg, #FFFFFF, #FFFFFF00)',
      border: '1px solid #6E1111',
      borderRightWidth: '0px',

      height: '432px',
      width: '942px',
      borderRadius: '30px',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: '79px',
    },
    header: {
      width: '815px',
      maxWidth: '815px',
      textAlign: 'left',

      color: 'rgba(126, 42, 42, 1)',
      fontFamily: 'Montserrat-bold',
      fontSize: `${85}px`,

      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    subHeader: {},

    image: {
      position: 'absolute',
      top: 0,
      right: `0px`,
      height: '100%',
      width: 'auto',
      // maxWidth: '1500px',
    },
    container: {
      position: 'absolute',
      left: `${118}px`,
      top: `${557}px`,

      width: `${815}px`,

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      gap: '25px',
      paddingTop: '100px',
      paddingLeft: '50px',
    },
    indexFeatureContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      padding: '23px 32px',
      background: 'linear-gradient(90deg, #FFFFFF, #FFFFFF00)',
      border: '1px solid #6E1111',
      borderRightWidth: '0px',
      position: 'relative',
      borderRadius: '30px',
    },
    featureContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    featureIndex: {
      display: 'none',
    },
    featureText: {
      color: '#7E2A2A',
      fontFamily: 'Montserrat-bold',
      fontSize: `${55}px`,
      minWidth: '705px',
      fontWeight: '600',
    },
    featureDescText: {
      color: '#FFF',
      fontFamily: 'Montserrat',
      fontSize: `${48}px`,
      fontWeight: '300',
    },
  },
  '8': {
    headersContainer: {
      position: 'absolute',
      left: `${0}px`,
      top: `${0}px`,
      width: '100%',
      height: '270px',
      display: 'flex',
      backgroundColor: '#0F0835',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '0px 0px 50px 50px',
    },
    header: {
      width: '100%',
      textAlign: 'center',

      color: '#FFF',
      fontFamily: 'Montserrat-bold',
      fontSize: `${85}px`,

      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
    },
    subHeader: {},

    image: {
      position: 'absolute',
      top: '270px',
      right: `0px`,
      height: 'calc(100% - 270px)',
      width: 'auto',
      // maxWidth: '1500px',
    },
    container: {
      position: 'absolute',
      left: `${83}px`,
      top: `${370}px`,

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexBasis: '100px',

      gap: '60px',
    },
    indexFeatureContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
    },
    featureIndex: {
      display: 'none',
    },
    featureContainer: {
      display: 'flex',
      minWidth: '600px',
      maxWidth: '600px',
      borderRadius: '30px',
      padding: '50px',
      paddingLeft: '23px',
      paddingRight: '23px',
      background: '#0F0835',
    },
    featureText: {
      textAlign: 'center',
      minWidth: '600px',
      color: '#FFF',
      fontFamily: 'Montserrat',
      fontSize: `${55}px`,

      fontWeight: '600',
    },
    featureDescText: {},
  },
  '9': {
    headersContainer: {
      position: 'absolute',
      left: `${0}px`,
      top: `${105}px`,

      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      width: `100%`,
      textAlign: 'center',
      textShadow: '0px 8px 8.6px rgba(0, 0, 0, 0.25)',
      color: '#3B0000',
      fontFamily: 'Montserrat-extra-bold',
      fontSize: `${134}px`,

      fontStyle: 'normal',
      fontWeight: '900',
      lineHeight: 'normal',
    },
    subHeader: {
      width: `100%`,
      textAlign: 'left',

      color: 'rgba(0, 12, 72, 1)',
      fontFamily: 'Montserrat',
      fontSize: `${73}px`,
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal',
    },

    image: {
      position: 'absolute',
      bottom: '0px',
      left: `50%`,
      transform: `translate(-50%, 0)`,

      height: '1000px',
      width: 'auto',
      // maxWidth: '1400px',
    },
    container: {
      position: 'absolute',
      left: `${131}px`,
      top: `${481}px`,

      width: `calc(100% - ${262}px)`,

      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      columnGap: '58px',
      rowGap: '20px',
    },
    indexFeatureContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      background: '#FFFCF5',
      minWidth: '590px',
      width: '590px',
      padding: '36px',
      paddingTop: '19px',
      paddingBottom: '19px',
    },
    featureContainer: {
      display: 'flex',
      width: '100%',
      paddingLeft: '20px',
      borderLeft: '1px solid #000',
    },
    featureIndex: {
      display: 'none',
    },
    featureText: {
      color: '#000C48',
      fontFamily: 'Montserrat',
      fontSize: `${55}px`,
      fontWeight: '500',
    },
    featureDescText: {
      color: '#000C48',
      fontFamily: 'Montserrat',
      fontSize: `${45}px`,
      fontWeight: '300',
    },
  },
};
