import React from 'react';
import { LoadIcon } from '@components/icons/loadIcon';
import './styles.css';
import { appColors } from '@config/theme';

export interface ImgGeneratorLoaderProps {
  className?: string;

  rootClassName?: string;

  loading?: boolean;

  style?: React.CSSProperties;

  tip?: React.ReactNode;

  wrapperClassName?: string;

  children?: React.ReactNode;

  withoutOpacity?: boolean;
}

export const ImgGeneratorLoader = ({
  children,
  withoutOpacity,
  rootClassName,
  tip,
  loading,
}: ImgGeneratorLoaderProps) => (
  // пока что только на весь экран сделал так что в children не имеет смысла, если надо будет потом надо добавить параметр, чтобы загрузка была только под размер children

  <>
    {loading && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          textAlign: 'center',
          backgroundColor: withoutOpacity
            ? appColors['blue-600']
            : appColors['blue-200'],
        }}
        className={`absolute min-w-screen min-h-screen w-full h-full  z-[3000] top-[0px] left-[0px] ${rootClassName}`}
      >
        <text className='text-white text-xl vsm:text-2xl font-semibold  z-[3000]'>
          {tip ?? 'Загрузка...'}
        </text>
        <LoadIcon />
      </div>
    )}
    {children}
  </>
);
