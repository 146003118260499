export const DesignIconMobile = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M23.8277 5.82773C22.7542 4.75415 21.0478 4.65188 19.8536 5.58954L13.2133 10.8038C12.353 11.4793 12.3829 12.7917 13.2729 13.4275C14.619 14.389 15.7966 15.5666 16.758 16.9126C17.3938 17.8027 18.7063 17.8325 19.3818 16.9722L24.596 10.3319C25.5337 9.13779 25.4314 7.4314 24.3578 6.35782L23.8277 5.82773ZM21.0888 7.16255C21.4869 6.84999 22.0557 6.88408 22.4135 7.24194L22.9436 7.77204C23.3015 8.1299 23.3356 8.69869 23.023 9.09673L18.1015 15.3642C17.1633 14.126 16.0596 13.0223 14.8213 12.084L21.0888 7.16255ZM12.9795 20.1186C12.4511 20.6471 11.0779 21.5241 9.60608 22.0715C9.48782 22.1154 9.37254 22.1559 9.26052 22.1929C9.66537 20.958 9.78302 19.8976 9.83984 19.0063C9.85496 18.7691 9.86548 18.5725 9.87448 18.4045C9.89599 18.0026 9.90878 17.7637 9.9548 17.5226C9.99627 17.3053 10.0369 17.2647 10.0807 17.2209L10.0812 17.2204C10.8815 16.42 12.1791 16.42 12.9795 17.2204C13.7798 18.0207 13.7798 19.3183 12.9795 20.1186ZM14.3937 21.5328C13.2642 22.6623 10.3816 24.237 8.25015 24.4687C7.70467 24.528 7.20837 24.4993 6.80325 24.3527C6.57494 24.2701 6.37559 24.15 6.2127 23.9871C7.70236 21.5808 7.79681 19.7992 7.86994 18.4196C7.92734 17.3367 7.97162 16.5015 8.667 15.8061C10.2484 14.2248 12.8123 14.2248 14.3937 15.8061C15.9751 17.3875 15.9751 19.9515 14.3937 21.5328ZM7.48647 22.4726C7.48656 22.4725 7.48885 22.4732 7.49305 22.4749C7.48848 22.4735 7.48637 22.4727 7.48647 22.4726Z'
      fill='#3987CF'
    />
  </svg>
);
