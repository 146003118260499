import { appColors } from '@config/theme';

export const NavigationPhotoIcon = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='27'
    height='20'
    viewBox='0 0 27 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.5302 18.5701V18.5701C3.02824 18.5701 1 16.5419 1 14.0399V13.0354C1 10.5459 3.01434 8.52776 5.50385 8.52776V8.52776C5.5163 8.52776 5.5302 8.51762 5.5302 8.50518V8.50518C5.5302 4.60831 8.64402 1.41239 12.5399 1.32716L13.5839 1.30432L14.4601 1.3239C18.3565 1.41095 21.4698 4.60781 21.4698 8.50518V8.50518C21.4698 8.51762 21.4799 8.52776 21.4923 8.52776V8.52776C23.9818 8.52776 26 10.5459 26 13.0354V14.0399C26 16.5419 23.9718 18.5701 21.4698 18.5701V18.5701'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
    />
    <path
      d='M16 12.1329L13.5 9.50775L11 12.1329'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M13.5 9.50775V18.6956'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
