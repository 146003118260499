export const DeleteIcon = () => (
  <svg
    width='55'
    height='55'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Regular / delete' clipPath='url(#clip0_66_1145)'>
      <path
        id='Vector'
        d='M6.66785 0.96582H9.34192V1.41577H10.3076V0.902832C10.3077 0.405029 9.90295 0 9.4054 0H6.60437C6.10681 0 5.70203 0.405029 5.70203 0.902832V1.41577H6.66785V0.96582Z'
        fill='red'
      />
      <path
        id='Vector_2'
        d='M12.5378 5.24219H3.47187C3.22346 5.24219 3.0279 5.4541 3.04792 5.70178L3.80585 15.0739C3.84809 15.597 4.28449 16 4.80878 16H11.2008C11.725 16 12.1614 15.597 12.2037 15.0737L12.9616 5.70178C12.9818 5.4541 12.7862 5.24219 12.5378 5.24219ZM5.66706 15.0004C5.65693 15.001 5.6468 15.0013 5.63679 15.0013C5.38361 15.0013 5.17109 14.8042 5.15534 14.5481L4.68037 6.85413C4.66401 6.58789 4.86652 6.35876 5.13264 6.34241C5.3979 6.32629 5.628 6.52832 5.64436 6.79468L6.11921 14.4886C6.13569 14.7549 5.93317 14.9839 5.66706 15.0004ZM8.49311 14.5184C8.49311 14.785 8.27692 15.0012 8.0102 15.0012C7.74348 15.0012 7.52729 14.785 7.52729 14.5184V6.82434C7.52729 6.55762 7.74348 6.34143 8.0102 6.34143C8.2768 6.34143 8.49311 6.55762 8.49311 6.82434V14.5184ZM11.3293 6.85278L10.8758 14.5468C10.8608 14.8033 10.6479 15.0012 10.3942 15.0012C10.3847 15.0012 10.3751 15.001 10.3654 15.0005C10.0992 14.9847 9.89607 14.7562 9.91181 14.49L10.3652 6.7959C10.3808 6.52966 10.6086 6.32654 10.8756 6.34229C11.1418 6.35791 11.3449 6.58655 11.3293 6.85278Z'
        fill='red'
      />
      <path
        id='Vector_3'
        d='M14.2077 3.75171L13.8906 2.80103C13.807 2.55042 13.5724 2.38135 13.3081 2.38135H2.70152C2.43736 2.38135 2.20262 2.55042 2.11912 2.80103L1.80199 3.75171C1.74083 3.93506 1.82042 4.12207 1.96898 4.21533C2.02952 4.2533 2.10118 4.27612 2.17992 4.27612H13.8298C13.9086 4.27612 13.9803 4.2533 14.0408 4.21521C14.1893 4.12195 14.2689 3.93494 14.2077 3.75171Z'
        fill='red'
      />
    </g>
    <defs>
      <clipPath id='clip0_66_1145'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
