import { Typography } from 'antd';
// import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@hooks/storeHook';
// import Circle from '@assets/images/templates/circle.png';

import { BannerTextArea } from './BannerTextArea';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { BannerMarks } from './BannerMarks';
import { MoveableElement } from './MovableElement';
import { MoveableImage } from './MoveableImage';

// import {  BannerItemPosition } from '@config/bannersConfig';
import { basicStyles } from './typeStyles/basicStyles';
import { __IS_DEV__ } from '@config/devConfig';
import { BannerFieldsKeys } from '@store/banner/BannerItem';
import { GENERATING_HEIGHT, GENERATING_WIDTH } from '@store/EditorStore';

type BannerViewItemProps = {
  width?: number;
  height: number;
  editing?: boolean;

  typeId?: number | null;
  generating?: boolean;
  // position: BannerItemPosition;
};

export const BannerViewItem = observer(
  ({
    width = GENERATING_WIDTH,
    height = GENERATING_HEIGHT,
    editing,

    typeId,
    generating,
    // position,
  }: BannerViewItemProps) => {
    const {
      editorStore: {
        banner,
        selectedFeatures,
        onDeleteFeature,
        setActiveElement,
      },
    } = useStore();

    const [scaleX, setScaleX] = useState(width / GENERATING_WIDTH);

    const [scaleY, setScaleY] = useState(height / GENERATING_HEIGHT);

    useLayoutEffect(() => {
      if (width !== GENERATING_WIDTH) {
        setScaleX(width / GENERATING_WIDTH);
      }
      if (height !== GENERATING_HEIGHT) {
        setScaleY(height / GENERATING_HEIGHT);
      }
    }, [width, height]);

    const onChangeText =
      (key: any) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        // console.log(key, event);
        banner?.onChangeTextField?.(key, event.target.value);
      };

    const maxLengthHeader = useMemo(() => {
      if (typeId === 2 || typeId === 5) {
        return 2;
      } else if (typeId === 7) {
        return 36;
      } else if (typeId === 3) {
        return 4;
      } else {
        return 18;
      }
    }, [typeId]);

    const scaleRatioHeader = useMemo(() => {
      if (typeId === 5) {
        return 1.3;
      } else if (typeId === 6 || typeId === 3) {
        return 1.7;
      } else {
        return 1;
      }
    }, [typeId]);

    const maxLengthFeature = useMemo(
      () =>
        typeId === 1 || typeId === 2 || typeId === 4
          ? 20
          : typeId === 5
            ? 30
            : typeId === 6
              ? 50
              : 30,
      [typeId],
    );

    // const positionKey = BannerConfig[typeId][position];
    // if (!positionKey) {
    //   return null;
    // }
    // const styles = typeStyles[typeId]?.[positionKey];
    // if (!styles) {
    //   return null;
    // }
    const styles = basicStyles[Number(typeId)] ?? null;
    const positionKey = '1';

    useEffect(() => {
      setActiveElement(`${BannerFieldsKeys.image}${typeId}_${positionKey}`);
    }, [typeId]);

    return (
      <>
        <div
          onDoubleClick={__IS_DEV__ ? banner?.resetFieldsPositions : void 0}
          id={'banner-for-save'}
          // onClick={(e) => console.log(e)}
          style={{
            width: width,
            height: height,
            cursor: editing ? 'auto' : 'default',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            overflow: 'hidden',
          }}
          className='bg-white flex relative rounded'
        >
          <img
            src={require(`@assets/images/templates/${typeId}.webp`)}
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          />
          <div
            style={{
              transform: `scaleX(${scaleX}) scaleY(${scaleY})`,
              transformOrigin: 'top left',
              height: `${GENERATING_HEIGHT}px`,
              minWidth: `${GENERATING_WIDTH}px`,
              pointerEvents: editing ? 'all' : 'none',
            }}
            className={`relative`}
          >
            {typeId === 4 && (
              <img
                width={1050}
                height={1050}
                style={styles.image}
                src={require('@assets/images/templates/circle.png')}
              />
            )}
            {typeId === 5 && (
              <img
                style={{
                  ...styles.image,
                  width: '100%',
                  maxWidth: 'unset',
                  left: '50%',
                  transform: `translate(-50%, 0)`,
                  right: 'unset',
                  height: '1123px',
                }}
                src={require('@assets/images/templates/figure.png')}
              />
            )}
            {banner?.image && (
              <MoveableImage
                generating={!editing || generating}
                element_id={`${BannerFieldsKeys.image}${typeId}_${positionKey}`}
                src={banner?.image}
                style={styles.image}
              />
            )}

            <MoveableElement
              element_id={`${BannerFieldsKeys.header}${typeId}_${positionKey}`}
              style={styles.headersContainer}
            >
              <BannerTextArea
                generating={generating}
                placeholder='Заголовок'
                onChange={onChangeText([BannerFieldsKeys.header])}
                defaultValue={banner?.fields[BannerFieldsKeys.header]}
                value={banner?.fields[BannerFieldsKeys.header]}
                style={styles.header}
                maxLength={maxLengthHeader}
                scaleRatio={scaleRatioHeader}
                fontSize={styles.header.fontSize}
              />
            </MoveableElement>
            <div style={styles.container}>
              {selectedFeatures.map((feature, index) => {
                if (typeId == 4 && index >= 3) {
                  return null;
                }
                return (
                  <MoveableElement
                    onDelete={onDeleteFeature.bind(this, index)}
                    element_id={`${BannerFieldsKeys.feature}${typeId}_${positionKey}_${index}`}
                    style={{
                      ...styles.indexFeatureContainer,
                      pointerEvents: editing
                        ? styles.indexFeatureContainer.pointerEvents
                        : 'none',
                    }}
                  >
                    <BannerMarks typeId={typeId} />
                    <Typography.Text style={styles.featureIndex}>
                      {index + 1}
                    </Typography.Text>

                    <div style={styles.featureContainer}>
                      <BannerTextArea
                        generating={generating}
                        placeholder='Критерий'
                        onChange={onChangeText(
                          `${BannerFieldsKeys.feature}${index + 1}`,
                        )}
                        defaultValue={
                          banner?.fields[
                            `${BannerFieldsKeys.feature}${index + 1}`
                          ] ?? feature
                        }
                        value={
                          banner?.fields[
                            `${BannerFieldsKeys.feature}${index + 1}`
                          ] ?? feature
                        }
                        maxLength={maxLengthFeature}
                        style={styles.featureText}
                        fontSize={styles.featureText.fontSize}
                      />
                    </div>
                  </MoveableElement>
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  },
);
