export const UploadIcon = () => (
  <svg
    width='82'
    height='55'
    viewBox='0 0 82 55'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.4966 53.6175V53.6175C7.49037 53.6175 1 47.1271 1 39.1208V37.438C1 29.4715 7.44589 23.0134 15.4123 23.0134V23.0134C15.4522 23.0134 15.4966 22.981 15.4966 22.9412V22.9412C15.4966 11.069 24.9898 1.33914 36.8594 1.09185L41.2685 1L45.1408 1.08239C57.0118 1.33497 66.5034 11.0675 66.5034 22.9412V22.9412C66.5034 22.981 66.5356 23.0134 66.5755 23.0134V23.0134C74.5419 23.0134 81 29.4715 81 37.4379V39.1208C81 47.1271 74.5096 53.6175 66.5034 53.6175V53.6175'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
    />
    <path
      d='M49 34L41 26L33 34'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M41 26V54'
      stroke='#3987CF'
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
