import { appColors } from '@config/theme';
import { useBoolState } from '@hooks/commonHooks';
import useScreenSize from '@hooks/getScreenSize';
import { Drawer } from 'antd';
import { ReactNode, useEffect } from 'react';
import './Header.css';
import { Logo } from '@components/icons/new-editor/Logo';
export const SecondHeaderDrawer = ({ children }: { children?: ReactNode }) => {
  const { sm } = useScreenSize();
  const [isOpened, open, close] = useBoolState(false);
  useEffect(() => {
    if (!sm && isOpened) close();
  }, [sm]);

  const handleLink = (link: string) => {
    window.location.href = link;
  };

  return (
    <div className='flex sm:hidden cursor-pointer'>
      <div
        className='flex justify-center items-start '
        onClick={isOpened ? close : open}
      >
        <div className='burger'>
          <span />
        </div>
      </div>
      <Drawer
        closeIcon={null}
        headerStyle={{ display: 'none' }}
        onClose={close}
        maskStyle={{ background: 'none' }}
        open={isOpened}
        placement='right'
        rootStyle={{ height: '100%' }}
        contentWrapperStyle={{
          height: '100%',
          boxShadow: 'none',
          width: '100%',
        }}
        bodyStyle={{ padding: 0, paddingBottom: '100px' }}
        drawerStyle={{
          background: appColors['screen-background'],
        }}
      >
        <div
          className='flex justify-between pt-[22px]  !min-h-[90px]  px-[20px] items-center '
          onClick={isOpened ? close : open}
        >
          {' '}
          <Logo
            onClick={handleLink.bind(this, 'https://card.mp360.ru/')}
            width={220}
            height={68}
          />
          <div className='burger'>
            <span />
          </div>
        </div>
        {children}
      </Drawer>
    </div>
  );
};
