import { observer } from 'mobx-react-lite';

import { useLogic, useStore } from '@hooks/storeHook';

import { LoadState } from '@@types/loadState';
import { LoadFileImgGenerator } from '@components/ui/LoadFileImgGenerator/LoadFileImgGenerator';
import { useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { CardImg } from '@components/ui/CardQuiz/CardQuiz';
import { LoadIcon } from '@components/icons/loadIcon';
import { appColors } from '@config/theme';

export const UploadPhotoComponent = observer(() => {
  const {
    editorStore: { projectData, imageLoading, banner },
  } = useStore();

  const logic = useLogic();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedImg, setImg] = useState<{ src: string }>({
    src: projectData?.image_path ?? '',
  });

  const onSelectedImg = ({ src }: { src: string }) => {
    setImg({ src });
  };

  async function beforeUpload(file: RcFile) {
    const form = new FormData();
    form.append('file', file);

    if (projectData?.id) {
      form.append('id', String(projectData.id));
    }

    const project = await logic.uploadImage(form);
    if (project && !!project.image_path) {
      onSelectedImg({
        src: project.image_path ?? '',
      });

      banner?.setImage(project.image_path);
    }
  }

  if (imageLoading === LoadState.Loading) {
    return (
      <div className='w-full h-full flex justify-center items-center'>
        <LoadIcon color={appColors.blue} />{' '}
      </div>
    );
  }

  return (
    <div className='flex gap-[22px] flex-col w-full h-full '>
      <LoadFileImgGenerator
        classNameDragger='w-full h-full'
        beforeUpload={beforeUpload}
      />
      <span className='sm:hidden'>
        {projectData?.image_path && (
          <CardImg
            key={projectData?.image_path}
            onClick={onSelectedImg.bind(this, {
              src: projectData?.image_path ?? '',
            })}
            isActive={true}
            img={projectData?.image_path ?? ''}
          />
        )}
      </span>
    </div>
  );
});
