import { appColors } from '@config/theme';

export const EditorMenuTextIcon = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M33.8859 1.5V0.5H31.8859V1.5V5.80462H27.5811H26.5811V7.80462H27.5811H31.8859V12.1093V13.1093H33.8859V12.1093V7.80462H38.1905H39.1905V5.80462H38.1905H33.8859V1.5ZM3.5 7.7823C2.94772 7.7823 2.5 8.23002 2.5 8.7823V36.1457C2.5 36.698 2.94772 37.1457 3.5 37.1457H30.8634C31.4157 37.1457 31.8634 36.698 31.8634 36.1457V17.5119C31.8634 16.9596 32.3111 16.5119 32.8634 16.5119C33.4157 16.5119 33.8634 16.9596 33.8634 17.5119V36.1457C33.8634 37.8025 32.5202 39.1457 30.8634 39.1457H3.5C1.84315 39.1457 0.5 37.8025 0.5 36.1457V8.7823C0.5 7.12545 1.84314 5.7823 3.5 5.7823H22.1338C22.6861 5.7823 23.1338 6.23002 23.1338 6.7823C23.1338 7.33458 22.6861 7.7823 22.1338 7.7823H3.5ZM16.5234 13L9 31.16H11.0754L13.1509 26.1011H21.712L23.7874 31.16H25.8629L18.3394 13H16.5234ZM17.4314 15.724L20.9337 24.2851H13.9291L17.4314 15.724Z'
      fill={color}
    />
  </svg>
);
