import { appColors } from '@config/theme';

export const NavigationTemplateIcon = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='25'
    height='25'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='1'
      y='1'
      width='23'
      height='23'
      rx='4'
      stroke={color}
      stroke-width='2'
    />
    <path
      d='M5.82352 17.5C5.82352 17.5 6.73466 15.6631 8.06098 15.6631C9.38729 15.6631 10.065 17.206 11.535 17.206C13.0038 17.206 14.6522 13.337 16.3174 13.337C17.9803 13.337 19.1765 16.0199 19.1765 16.0199'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M10.389 9.24821C10.389 10.2132 9.60693 10.9965 8.64081 10.9965C7.67581 10.9965 6.89371 10.2132 6.89371 9.24821C6.89371 8.28321 7.67581 7.5 8.64081 7.5C9.60693 7.50112 10.389 8.28321 10.389 9.24821Z'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
