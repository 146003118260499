import { PropsWithChildren } from 'react';
import { MenuButtonsComponent } from './MenuButtonsComponent';
import { MenuType } from '@hooks/templateMenuHooks';

export const DesktopComponent = ({
  text,
  selectedMenu,
  setMenu,
  children,
}: PropsWithChildren & {
  text: string;
  selectedMenu: MenuType;
  setMenu: React.Dispatch<React.SetStateAction<MenuType>>;
}) => (
  <span className='gap-[20px] max-h-[540px] laptop:max-h-[740px] minidesktop:max-h-[840px] flex flex-col '>
    <text className='h-[36px] font-bold text-xl'>{text}</text>
    <div className='w-[670px] max-h-[484px] laptop:max-h-[684px] minidesktop:max-h-[784px]  rounded bg-white flex flex-row '>
      <div className='w-[223px] border-solid border-0 border-r-[1px]  border-grey-secondary pt-[20px] flex flex-col'>
        <MenuButtonsComponent setMenu={setMenu} selectedMenu={selectedMenu} />
        <a
          target='_blank'
          href='https://youtu.be/OejrTs1S1U8?si=WfSUDkx3BslDM0YT'
          style={{ marginTop: 'auto' }}
        >
          <img
            width={183}
            height={187}
            src={require('@assets/images/lesson.png')}
          />
        </a>
      </div>
      <div className='w-full h-[484px] laptop:h-[684px] minidesktop:h-[784px]  overflow-y-auto overflow-x-hidden mx-[-8px]'>
        <div className='w-full h-[484px] laptop:h-[684px]  minidesktop:h-[784px]  p-[20px] '>
          {children}
        </div>
      </div>
    </div>
  </span>
);
