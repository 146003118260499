import { appColors } from '@config/theme';

export const EditorMenuObjectIconMobile = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.00024 30.9784C5.00024 30.9784 6.97906 26.9891 9.85956 26.9891C12.7401 26.9891 14.212 30.3399 17.4045 30.3399C20.5945 30.3399 24.1744 21.9373 27.7909 21.9373C31.4024 21.9373 34.0002 27.764 34.0002 27.764'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M14.9156 13.7968C14.9156 15.8926 13.217 17.5936 11.1188 17.5936C9.02303 17.5936 7.32446 15.8926 7.32446 13.7968C7.32446 11.701 9.02303 10 11.1188 10C13.217 10.0024 14.9156 11.701 14.9156 13.7968Z'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
