import { appColors } from '@config/theme';

export const NavigationFeaturesIconMobile = ({
  color = appColors.blue,
}: {
  color?: string;
}) => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M15 7L17.472 12.266L23 13.1157L19 17.2124L19.944 23L15 20.266L10.056 23L11 17.2124L7 13.1157L12.528 12.266L15 7Z'
      stroke={color}
      stroke-width='2'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
