import { ApiDinymicObject, ApiUser } from '@@types/auth';
import { LoadState } from '@@types/loadState';

import { computed, observable } from 'mobx';

export class AuthStore {
  @observable
  token: string | null = null;

  @observable
  user: ApiUser | null = null;

  @observable
  logInLoading = LoadState.Initial;

  @observable
  registrationLoading = LoadState.Initial;

  @observable
  typeRentry: ApiDinymicObject = {};

  @observable
  typeRentryLoading = LoadState.Initial;

  @observable
  typeSendCode: ApiDinymicObject = {};

  @observable
  typeSendCodeLoading = LoadState.Initial;

  @observable
  languageApp: ApiDinymicObject = {};

  @observable
  languageAppLoading = LoadState.Initial;

  get isLoggedIn() {
    return this.token !== null;
  }

  @computed
  get headerTitle() {
    return this.user?.name &&
      this.user?.name.length <= 18 &&
      this.user?.surname &&
      this.user.surname.length <= 18
      ? `${this.user?.name} ${this.user?.surname}`
      : this.user?.email
      ? this.user?.email
      : '';
  }
}
