import { AppContext } from '@context/appContexts'
import { useContextRequired } from '@hooks/commonHooks'



export const useAppContext = () => useContextRequired(AppContext, 'AppContext')

// TODO: Reorganize files in store, logic folders
export const useStore = () => useAppContext().store

export const useLogic = () => useAppContext().logic