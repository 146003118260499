export const BlackRingActiveIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    viewBox='0 0 18 18'
    fill='none'
  >
    <g id='Chek'>
      <path
        id='Vector'
        d='M5.66941 8.5L8 11L12.6694 6'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);
