export const SmallCloseIcon = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M22.6153 8.70711C23.0059 8.31658 23.0059 7.68342 22.6153 7.29289C22.2248 6.90237 21.5917 6.90237 21.2011 7.29289L14.9541 13.5399L8.70714 7.29289C8.31661 6.90237 7.68345 6.90237 7.29292 7.29289C6.9024 7.68342 6.9024 8.31658 7.29292 8.70711L13.5399 14.9541L7.29289 21.2011C6.90237 21.5917 6.90237 22.2248 7.29289 22.6153C7.68342 23.0059 8.31658 23.0059 8.70711 22.6153L14.9541 16.3683L21.2012 22.6153C21.5917 23.0059 22.2249 23.0059 22.6154 22.6153C23.0059 22.2248 23.0059 21.5917 22.6154 21.2011L16.3683 14.9541L22.6153 8.70711Z'
      fill='#3987CF'
    />
  </svg>
);
