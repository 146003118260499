import { useStore } from '@hooks/storeHook';
import { observer } from 'mobx-react-lite';

export interface ProLayoutProps {
  className?: string;

  rootClassName?: string;

  style?: React.CSSProperties;

  wrapperClassName?: string;

  children?: React.ReactNode;

  hideIcon?: boolean;
}

export const ProLayout = observer(({ children, hideIcon }: ProLayoutProps) => {
  const store = useStore();

  return (
    <>
      <span
        onClick={store.setProModal.bind(this, true)}
        className='relative w-full sm:w-[unset]'
      >
        {children}
        <div className='absolute right-[0px] top-[0px]'>
          {!hideIcon && <ProIcon />}
        </div>
      </span>
    </>
  );
});

const ProIcon = () => (
  <svg
    width='51'
    height='30'
    viewBox='0 0 51 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 0H51V30H10C4.47715 30 0 25.5228 0 20V0Z' fill='#FFF965' />
    <path
      d='M13.6318 17.374V21H11.5318V10.948H16.1238C18.0698 10.948 19.2458 12.194 19.2458 14.168C19.2458 15.078 18.7418 17.374 16.0398 17.374H13.6318ZM13.6318 15.638H15.5778C17.1458 15.638 17.1458 14.518 17.1458 14.07C17.1458 13.09 16.5998 12.684 15.2978 12.684H13.6318V15.638ZM20.8697 21V10.948H25.9517C28.6257 10.948 29.1157 12.922 29.1157 13.79C29.1157 14.896 28.5557 15.848 27.5617 16.184C28.3877 16.548 28.8357 16.856 28.8357 18.732C28.8357 20.202 28.8357 20.566 29.2837 20.734V21H26.9597C26.8197 20.524 26.7357 20.006 26.7357 18.97C26.7357 17.598 26.6517 17.066 25.0977 17.066H22.9277V21H20.8697ZM22.9277 15.372H25.3917C26.4837 15.372 27.0157 14.994 27.0157 14C27.0157 13.468 26.7777 12.684 25.5457 12.684H22.9277V15.372ZM30.2517 15.974C30.2517 11.256 33.7517 10.682 35.0817 10.682C36.4117 10.682 39.9117 11.256 39.9117 15.974C39.9117 20.692 36.4117 21.266 35.0817 21.266C33.7517 21.266 30.2517 20.692 30.2517 15.974ZM32.3517 15.974C32.3517 18.788 33.9477 19.488 35.0817 19.488C36.2157 19.488 37.8117 18.788 37.8117 15.974C37.8117 13.16 36.2157 12.46 35.0817 12.46C33.9477 12.46 32.3517 13.16 32.3517 15.974Z'
      fill='#1F1F1F'
    />
  </svg>
);
