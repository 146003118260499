import { useState, useEffect } from 'react';

function getScreenSizes() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    vsm: width < 375,
    sm: width < 640,
    md: width < 768,
    lg: width < 1024,
    xl: width < 1280,
    xxl: width < 1536,
    laptop: height < 800,
    minidesktop: height < 1000,
  };
}

export default function useScreenSize() {
  const [screenSizes, setScreenSizes] = useState(getScreenSizes());

  useEffect(() => {
    function handleResize() {
      setScreenSizes(getScreenSizes());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSizes;
}
