export const DownloadIconMobile = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M16.396 7C16.396 6.44772 15.9483 6 15.396 6C14.8438 6 14.396 6.44772 14.396 7V16.2604L10.994 14.3017C10.5154 14.0261 9.904 14.1907 9.62843 14.6693C9.35286 15.148 9.51747 15.7594 9.99609 16.0349L14.8818 18.8479C14.8924 18.8543 14.9031 18.8605 14.914 18.8665L15.396 19.144L15.8783 18.8664C15.889 18.8604 15.8996 18.8543 15.9101 18.848L20.796 16.0349C21.2746 15.7594 21.4392 15.148 21.1637 14.6693C20.8881 14.1907 20.2767 14.0261 19.7981 14.3017L16.396 16.2604V7ZM4 22.7426C4 22.1903 4.44772 21.7426 5 21.7426H25.198C25.7503 21.7426 26.198 22.1903 26.198 22.7426C26.198 23.2949 25.7503 23.7426 25.198 23.7426H5C4.44772 23.7426 4 23.2949 4 22.7426Z'
      fill='#3987CF'
    />
  </svg>
);
