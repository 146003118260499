import {
  Context,
  DependencyList,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

/**
 * Use this constant as default context value along with the {@link useContextRequired} hook
 * to check that context is provided at runtime.
 */
export const contextNotProvidedError = 'Context was not provided!';

/**
 * Use this hook along with the {@link contextNotProvidedError} to check that context is provided at runtime.
 */
export const useContextRequired = <T>(
  context: Context<T>,
  contextName: string,
): T => {
  const providedContext = useContext<T>(context);

  if (providedContext === (contextNotProvidedError as any)) {
    throw new Error(`The ${contextName} was not provided!`);
  }

  return providedContext;
};

/**
 * Kinda like didMount, but not really.
 * Default `deps` are an empty array.
 * Pass `null` to emulate useEffect without the `deps` parameter.
 * Use carefully with deps, rules of hooks are not enforced.
 */
export const useAnyEffect = (
  func: () => unknown,
  deps: DependencyList | null = [],
) => {
  const effect = () => {
    func();
  };

  const args: [VoidFunction] | [VoidFunction, DependencyList] =
    deps === null ? [effect] : [effect, deps];

  // @ts-expect-error - destructuring DU of tuple types
  useEffect(...args);
};

/**
 * Kinda like willUnmount, but not really.
 * Default `deps` are empty.
 */
export const useAnyUnEffect = (func: () => unknown) =>
  useEffect(
    () => () => {
      func();
    },
    [],
  );

export const useBoolState = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return [value, setTrue, setFalse] as const;
};

export const useToggleState = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const toggleValue = useCallback(() => setValue(!value), [value]);

  return [value, toggleValue] as const;
};

export const usePrevious = <T>(value: T) => {
  const valueRef = useRef<T>(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value, valueRef]);

  return valueRef.current;
};

export const useIsMounted = () => {
  // Can't use state, because it's updated after unmount
  // It also needs to be a box with the value inside
  const isMounted = useRef(true);

  useAnyUnEffect(() => (isMounted.current = false));

  return isMounted;
};
