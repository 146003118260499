import { ImgGeneratorButton } from '../Button/Button';

import { Layout } from 'antd';
import './Header.css';

// import { LeftSideButtons } from './LeftSideButtons';

import { Logo } from '@components/icons/new-editor/Logo';

import { Dropdown } from 'antd';
import { PropsWithChildren } from 'react';

import { ProLayout } from '../ProLayout/ProLayout';
import useScreenSize from '@hooks/getScreenSize';
import { SecondHeaderDrawer } from './SecondHeaderDrawer';

const handleLink = (link: string) => {
  window.location.href = link;
};

export const SecondHeader = () => {
  const { Header } = Layout;

  const { sm } = useScreenSize();

  return (
    <Header className='!w-full !p-[0px] flex justify-center bg-transparent !pt-[22px] sm:!pt-[33px] !h-full  z-header overflow-hidden'>
      <div className='w-full !h-[68px] flex items-center h-full max-w-[1440px] px-[20px]'>
        <div className='flex flex-row justify-between items-center w-[695px] h-full flex '>
          <Logo onClick={handleLink.bind(this, 'https://card.mp360.ru/')} />

          {!sm && <LeftSideButtons />}
        </div>

        {!sm && <RightSideButtons />}
        <SecondHeaderDrawer>
          <LeftSideButtons />
          <RightSideButtons />
        </SecondHeaderDrawer>
      </div>
    </Header>
  );
};

const LeftSideButtons = () => (
  <div className='flex flex-col items-center sm:flex-row sm:justify-end mt-[60px] gap-[33px] sm:gap-[0px] mb-[120px] sm:mb-[0] sm:mt-[0]'>
    <Dropdown
      // onOpenChange={setDropdown}
      placement='bottomLeft'
      dropdownRender={() => (
        <div
          style={{ boxShadow: '1px 1px 10px 0 rgb(0 0 0 / 11%)' }}
          className='w-[230px]  bg-white rounded-[5px] flex flex-col'
        >
          <DropdownItem link='https://mp360.ru/moysklad-i-willdberries.html'>
            Wildberries
          </DropdownItem>
          <DropdownItem link='https://mp360.ru/moysklad-i-ozon.html'>
            Ozon
          </DropdownItem>
          <DropdownItem link='https://mp360.ru/moysklad-i-yandex-market.html'>
            Яндекс.Маркет
          </DropdownItem>
        </div>
      )}
      overlayStyle={{ top: '90px' }}
    >
      <div className='group flex justify-start min-w-[52px] sm:mr-[40px] cursor-pointer items-center items-center'>
        <text
          onClick={handleLink.bind(this, 'https://mp360.ru/soft.html')}
          style={{ fontFamily: 'Roboto-reg' }}
          className='text-[18px] leading-[22px]  tracking-[0.03rem] font-normal border-0 group-hover:border-b border-blue border-solid'
        >
          Софт
        </text>
        <text className='group-hover:rotate-180 text-[8px] ml-[6px]'>▼</text>
      </div>
    </Dropdown>
    <div className='group flex justify-start min-w-[200px] sm:mr-[40px] cursor-pointer items-center items-center'>
      <text
        onClick={handleLink.bind(this, 'https://card.mp360.ru/')}
        style={{ fontFamily: 'Roboto-reg' }}
        className='text-[18px] tracking-[0.03rem] leading-[22px] font-normal border-0 group-hover:border-b border-blue border-solid'
      >
        Создание инфографики
      </text>
    </div>
    <Dropdown
      // onOpenChange={setDropdown}
      placement='bottomLeft'
      dropdownRender={() => (
        <div
          style={{ boxShadow: '1px 1px 10px 0 rgb(0 0 0 / 11%)' }}
          className='w-[230px]  bg-white rounded-[5px] flex flex-col'
        >
          <DropdownItem link='https://mp360.ru/guide/sozdaem-magazin-na-wildberries.html'>
            Уроки по Wildberries
          </DropdownItem>
          <DropdownItem link='https://mp360.ru/uroki-ozon/tovar.html'>
            Уроки по Ozon
          </DropdownItem>
          <DropdownItem link='https://mp360.ru/guide/calculator-wildberries.html'>
            Калькулятор Wildberries
          </DropdownItem>
          <DropdownItem link='https://mp360.ru/uroki-ozon/calculator.html'>
            Калькулятор Ozon
          </DropdownItem>
          <DropdownItem link='https://mp360.ru/blog/'>Блог</DropdownItem>
        </div>
      )}
      overlayStyle={{ top: '90px' }}
    >
      <div className='group flex justify-start min-w-[52px] cursor-text items-center items-center'>
        <text
          style={{ fontFamily: 'Roboto-reg' }}
          className='text-[18px] leading-[22px]  tracking-[0.03rem] font-normal border-0 group-hover:border-b border-blue border-solid'
        >
          Уроки
        </text>
        <text className='group-hover:rotate-180 text-[8px] ml-[6px]'>▼</text>
      </div>
    </Dropdown>
  </div>
);

const RightSideButtons = () => (
  <div className='flex flex-col items-center sm:flex-row sm:justify-between ml-auto sm:items-center h-full '>
    <div className='flex flex-col sm:flex-row items-center sm:justify-start gap-[33px] sm:gap-[0]'>
      <div className='group flex justify-start min-w-[146px] mr-[25px] cursor-pointer items-center items-center'>
        <text
          onClick={handleLink.bind(this, 'tel:+78005553449')}
          style={{ fontFamily: 'Roboto-reg' }}
          className='text-[24px] text-blue  sm:text-black sm:text-[18px] leading-[22px]  tracking-[0.03rem] font-bold border-0  border-blue border-solid'
        >
          8 (800) 555-34-49
        </text>
      </div>
      <ProLayout hideIcon>
        <ImgGeneratorButton
          styles={{
            // @ts-ignore

            backgroundColor: '#2B78B8',
          }}
          className='!min-w-[241px] !w-[241px] !max-w-[241px] !min-h-[52.67px] !max-h-[52.67px] sm:mr-[25px] hover:!bg-[#538DBF] '
        >
          Бесплатная консультация
        </ImgGeneratorButton>
      </ProLayout>
      <div className='group flex justify-start min-w-[52px] cursor-pointer items-center items-center'>
        <text
          onClick={handleLink.bind(this, 'https://app.mpsklad.ru/login')}
          style={{ fontFamily: 'Roboto-reg' }}
          className='text-[18px] leading-[22px]  tracking-[0.03rem] font-normal border-0 border-blue border-solid'
        >
          Войти
        </text>
      </div>
    </div>
  </div>
);

const DropdownItem = ({
  children,
  link,
}: PropsWithChildren & { sm?: boolean; link?: string }) => (
  <div
    onClick={handleLink.bind(this, link ?? 'mp360.ru')}
    style={{
      borderBottom: '1px solid rgb(210 210 212 / 63%)',
      padding: '10px 25px',
    }}
    className='w-[240px] h-[40px] px-[10px] flex justify-start items-center cursor-pointer'
  >
    <a
      style={{ fontFamily: 'Roboto-reg' }}
      className={'text-[16px] text-[#2288DD] hover:text-[#95AAFF] w-[240px]'}
    >
      {children}
    </a>
  </div>
);
