export type DictionaryType = typeof dictionary.en;

export const dictionary = {
  en: {
    test: {
      Hello: 'Hello',
    },
    chat: {
      placeholder: 'Add a comment, @ to mention',
    },
    menu: {
      collapse: 'Collapse',
    },
    layoutsMenu: {
      header: 'Layouts',
      tag: 'I am tag',
    },
    brandKitMenu: {
      header: 'Brand Kit',
      logo: 'Logo',
      addLogo: 'Add logo',
      colors: 'Colors',
      fonts: 'Fonts',
      title: 'Title',
      font36: 'Montserrat; 36 px',
      headline: 'Headline',
      font24: 'Montserrat; 24 px',
      normalText: 'Normal text',
      font18: 'Montserrat; 18 px',
      small: 'Small text',
      font16: 'Montserrat; 16 px',
      photos: 'Photos',
      edit: 'Edit',
      seeAll: 'See all',
      brandColors: 'Brand colors',
      colorPalette: 'Color palette',
      choiseAFont: 'Choose a font',
    },
    photosMenu: {
      header: 'Photos',
    },
    uploadMenu: {
      header: 'Uploads',
    },
    styleMenu: {
      header: 'Style',
    },
    addMenu: {
      header: 'Add',
    },
    header: {
      Back: 'Back',
      File: 'File',
      Help: 'Help',
      Preview: 'Preview',
      Continue: 'Continue',
      Title: 'Anti-age serum',
      NewProject: 'New project',
    },
    footer: {
      theProduct: 'The product',
      Title: 'Title',
      Details: 'Details',
      Platform: 'Platform',
      Targetaudience: 'Target audience',
      Features: 'Features',
      Template: 'Template',
      GeneralInformation: 'General information',
      Design: 'Design',
      Copywriting: 'Copywriting',
      Overview: 'Overview',
      Step: 'Step',
    },
    filePopover: {
      CreateNewListing: 'Create new listing',
      Save: 'Save',
      SaveToFolder: 'Save to folder',
      MakeACopy: 'Make a copy',
      AllChangesSaved: 'All changes saved',
      CreateNew: 'Create New',
    },
    searchFiled: {
      Placeholder: 'Search all folders',
      CopywritingFeatures: 'Add people, groups, or your team',
      TypePlatform: 'Type the platform',
      Natural: 'Natural',
    },
    filePopoverTabs: {
      Recents: 'Recents',
      All: 'All',
    },
    avatarPopover: {
      PeopleWithAccess: 'People with access',
      Edit: 'Edit',
      CollaboratingLink: 'Collaborating link',
      OnlyYouCanAccess: 'Only you can access',
      CopyLink: 'Copy link',
      AddPeopleGroupsPlaceholder: 'Add people, groups, or your team',
      SelectCountry: 'Select your country',
    },
    Copywriting: {
      advancedSettings: 'Advanced settings',
      update: 'Update',
      allAssistant: 'AI assistant',
      addBulletPoint: 'Add bullet point',
    },
    Preview: {
      generatedSearchVolume: 'Generated search volume',
      ratings: ' ratings',
    },
    TemplatePreview: {
      EditTemplate: 'Edit this template',
    },
    templateUpldoadQuiz: {
      uploadPhotoProduct: 'Upload photo of your product',
      chooseOnTheese: 'Or choose on of theese',
      loadingImg: 'Removing background',
      loadingTip: 'Asking AI',
    },
    account: {
      homePage: {
        recentHeader: 'Your recent projects',
        yourAccount: 'Your account',
        completeProfile:
          'Complete account settings and get 5 listings for free!',
        openSettings: 'Open Settings',
        getFree: 'Get 5 listings for free',
        joinAffilate: 'Join our affiliate program',
        findOut: 'Find out more',
        educationHub: 'Education hub',
        allPosts: 'all posts',
        reccomendation: 'Amazon listing recomendations 2023',
        yourPlan: 'Your plan',
        usedCredits: 'Used credits',
        upgradePlan: 'Upgrade plan',
      },
      navigation: {
        Home: 'Home',
        Projects: 'Projects',
        Products: 'Products',
        Upload: 'Upload',
        Team: 'Team',
        AccountSettings: 'Account settings',
        Billing: 'Billing and Plans',
        Affiliate: 'Affiliate',
        Loyalty: 'Loyalty',
        LogOut: 'Log out',
        All: 'All',
        Recent: 'Recent',
        Drafts: 'Drafts',
        Folders: 'Folders',
        Purchased: 'Purchased',
        Trash: 'Trash',
        Back: 'Back',

        Verify: 'Verify',
        Preferences: 'Preferences',
      },
      footer: {
        leftText:
          'Add a luxury green Instagram template kit is designed in Canva and fully customizable it',
        inputText: 'Write text',
        buttonText: 'submit',
        company: 'Company',
        aboutUs: 'About Us',
        contactUs: 'Contact Us',
        team: 'Team',
        career: 'Career',
        affiliates: 'Affiliates',
        refferals: 'Referrals',
        resourses: 'Resourses',
        blog: 'Blog',
        privatePolicy: 'Privacy Policy',
        Terms: 'Terms of service',
        FAQ: 'FAQ',
        pricing: 'Pricing',
        quickLinks: 'Quick Links',
        home: 'Home',
        startNow: 'Start now',
        login: 'Login',
        signUp: 'Sign Up',
        contacts: 'Contacts',
        Adress: 'Adress:',
        Email: 'Email:',
        rights: 'ImgGenerator.Pro 2023 All rights reserved',
      },
      Settings: {
        YourAccount: 'Your Account',
      },
    },
    login: {
      Welcome: 'Welcome to ImgGenerator',
      enterEmail: 'Please enter your e-mail to continue',
      SignIn: 'Sign in',
      SignUp: 'Signup',
      Continue: 'Continue',
      Or: 'or',
      CheckYourEmail: 'Check your email',
      EnterPass: 'Enter your password',
      YourChoise: 'Вы выбрали вход по password',
      Cancel: 'Cancel',
      Verify: 'Verify',
      ContinueWith: 'Continue with',
      sentCode: 'We have sent a code to',
      havenotcode: 'Didn`t get a code?',
      resend: 'Resend',
    },
  },

  ru: {
    test: {
      Hello: 'Привет',
    },
    chat: {
      placeholder: 'Добавить комментарий, @ для упоминания',
    },
    menu: {
      collapse: 'Свернуть',
    },
    layoutsMenu: {
      header: 'Макеты',
      tag: 'Я тег',
    },
    brandKitMenu: {
      header: 'Фирменный стиль',
      logo: 'Логотип',
      addLogo: 'Добавить логотип',
      colors: 'Цвета',
      fonts: 'Шрифты',
      title: 'Заголовок',
      font36: 'Montserrat; 36 px',
      headline: 'Заголовок',
      font24: 'Montserrat; 24 px',
      normalText: 'Обычный текст',
      font18: 'Montserrat; 18 px',
      small: 'Маленький текст',
      font16: 'Montserrat; 16 px',
      photos: 'Фотографии',
      edit: 'Редактировать',
      seeAll: 'Посмотреть все',
      brandColors: 'Фирменные цвета',
      colorPalette: 'Палитра цветов',
      choiseAFont: 'Выбрать шрифт',
    },
    photosMenu: {
      header: 'Фотографии',
    },
    uploadMenu: {
      header: 'Загрузки',
    },
    styleMenu: {
      header: 'Стиль',
    },
    addMenu: {
      header: 'Добавить',
    },
    header: {
      Back: 'Назад',
      File: 'Файл',
      Help: 'Помощь',
      Preview: 'Предпросмотр',
      Continue: 'Продолжить',
      Title: 'Сыворотка против старения',
      NewProject: 'Новый проект',
    },
    footer: {
      theProduct: 'Продукт',
      Title: 'Заголовок',
      Details: 'Детали',
      Platform: 'Платформа',
      Targetaudience: 'Целевая аудитория',
      Features: 'Характеристики',
      Template: 'Шаблон',
      GeneralInformation: 'Общая информация',
      Design: 'Дизайн',
      Copywriting: 'Копирайтинг',
      Overview: 'Обзор',
      Step: 'Шаг',
    },
    filePopover: {
      CreateNewListing: 'Создать новое объявление',
      Save: 'Сохранить',
      SaveToFolder: 'Сохранить в папку',
      MakeACopy: 'Сделать копию',
      AllChangesSaved: 'Все изменения сохранены',
      CreateNew: 'Создать новый',
    },
    searchFiled: {
      Placeholder: 'Поиск по всем папкам',
      CopywritingFeatures: 'Добавить людей, группы или вашу команду',
      TypePlatform: 'Введите платформу',
      Natural: 'Натуральный',
    },
    filePopoverTabs: {
      Recents: 'Недавние',
      All: 'Все',
    },
    avatarPopover: {
      PeopleWithAccess: 'Люди с доступом',
      Edit: 'Редактировать',
      CollaboratingLink: 'Совместная ссылка',
      OnlyYouCanAccess: 'Только вы можете получить доступ',
      CopyLink: 'Копировать ссылку',
      AddPeopleGroupsPlaceholder: 'Добавить людей, группы или вашу команду',
      SelectCountry: 'Выберите вашу страну',
    },
    Copywriting: {
      advancedSettings: 'Расширенные настройки',
      update: 'Обновить',
      allAssistant: 'AI помощник',
      addBulletPoint: 'Добавить маркерный пункт',
    },
    Preview: {
      generatedSearchVolume: 'Сгенерированный объем поиска',
      ratings: ' рейтинги',
    },
    TemplatePreview: {
      EditTemplate: 'Редактировать этот шаблон',
    },
    templateUpldoadQuiz: {
      uploadPhotoProduct: 'Загрузите фото вашего продукта',
      chooseOnTheese: 'Или выберите один из них',
      loadingImg: 'Удаление фона',
      loadingTip: 'Запрос к AI',
    },
    account: {
      homePage: {
        recentHeader: 'Ваши последние проекты',
        yourAccount: 'Ваш аккаунт',
        completeProfile:
          'Завершите настройки аккаунта и получите 5 объявлений бесплатно!',
        openSettings: 'Открыть настройки',
        getFree: 'Получить 5 объявлений бесплатно',
        joinAffilate: 'Присоединяйтесь к нашей партнерской программе',
        findOut: 'Узнать больше',
        educationHub: 'Образовательный центр',
        allPosts: 'все посты',
        reccomendation: 'Рекомендации для объявлений на Amazon 2023 года',
        yourPlan: 'Ваш план',
        usedCredits: 'Использованные кредиты',
        upgradePlan: 'Обновить план',
        welcome: 'Добро пожаловать,',
      },
      navigation: {
        Home: 'Главная',
        Projects: 'Проекты',
        Products: 'Продукты',
        Upload: 'Загрузить',
        Team: 'Команда',
        AccountSettings: 'Настройки',
        Billing: 'Выставление счетов',
        Affiliate: 'Аффиляты',
        Loyalty: 'Лояльность',
        LogOut: 'Выйти',
        All: 'Все',
        Recent: 'Недавние',
        Drafts: 'Черновики',
        Folders: 'Папки',
        Purchased: 'Купленные',
        Trash: 'Корзина',
        Back: 'Назад',

        Verify: 'Подтвердить',
        Preferences: 'Настройки',
      },
      footer: {
        leftText:
          'Добавьте роскошный зеленый набор шаблонов для Instagram, разработанный в Canva и полностью настраиваемый.',
        inputText: 'Написать текст',
        buttonText: 'отправить',
        company: 'Компания',
        aboutUs: 'О нас',
        contactUs: 'Свяжитесь с нами',
        team: 'Команда',
        career: 'Карьера',
        affiliates: 'Партнеры',
        refferals: 'Рефералы',
        resourses: 'Ресурсы',
        blog: 'Блог',
        privatePolicy: 'Политика конфиденциальности',
        Terms: 'Условия использования',
        FAQ: 'Часто задаваемые вопросы',
        pricing: 'Ценообразование',
        quickLinks: 'Быстрые ссылки',
        home: 'Главная',
        startNow: 'Начать сейчас',
        login: 'Вход',
        signUp: 'Регистрация',
        contacts: 'Контакты',
        Adress: 'Адрес:',
        Email: 'Эл. почта:',
        rights: 'ImgGenerator.Pro 2023 Все права защищены',
      },
      Settings: {
        YourAccount: 'Ваш аккаунт',
      },
    },
    login: {
      Welcome: 'Добро пожаловать в ImgGenerator',
      enterEmail:
        'Пожалуйста, введите свой адрес электронной почты, чтобы продолжить',
      SignIn: 'Войти',
      SignUp: 'Зарегистрироваться',
      Continue: 'Продолжить',
      Or: 'или',
      CheckYourEmail: 'Проверьте свою почту',
      EnterPass: 'Введите ваш пароль',
      YourChoise: 'Вы выбрали вход по паролю',
      Cancel: 'Отмена',
      Verify: 'Подтвердить',
      ContinueWith: 'Продолжить с помощью',
      sentCode: 'Мы отправили код на почту',
      havenotcode: 'Не получили код?',
      resend: 'Отправить снова',
    },
    qiuz: {
      link: 'Ссылка на товар Wildberries',
      putLink: 'Вставьте ссылку',
      choose: 'Выбрать',
      continue: 'Продолжить',
      skip: 'Пропустить',
      choosePosition: 'Выберите расположение товара',
      chooseTemplate: 'Выберите стиль',
      chooseFeatures: 'Выберите преимущества',
      mainFeatures: 'Основные преимущества',
      choosedFeatures: 'Выбранные преимущества',
      takeFrom: 'Ссылка на товары конкурентов',
    },
  },
};
