import { ReactNode, useEffect } from 'react';
import { LoadState } from '@@types/loadState';

import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { appColors } from '@config/theme';
import { LoadIcon } from '@components/icons/loadIcon';
import { useStore } from '@hooks/storeHook';
import { setNavigate } from '@config/navigation';

export const RootLoader = observer(({ children }: { children: ReactNode }) => {
  const { rootLoading } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    setNavigate(navigate);
  }, []);

  if (rootLoading === LoadState.Error) {
    return (
      // @ts-ignore
      <div style={styles.container}>
        <text className='text-white'>{'Произошла ошибка :('}</text>
      </div>
    );
  }

  if (rootLoading === LoadState.Loading) {
    return (
      //@ts-ignore
      <div style={styles.container}>
        <LoadIcon />
      </div>
    );
  }

  if (rootLoading === LoadState.Success) return <>{children}</>;
  else return null;
});

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: appColors.blue,
    gap: '24px',
  },
};
